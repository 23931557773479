import {
  BaggageHandIcon,
  BaggageIcon,
  Card,
  DollarIcon,
  PlaneFillIcon,
  Refund2LineIcon,
  SeatClassIcon,
} from '@common-ui';
import {
  IFlightClass,
  IFlightInfo,
  ISegment,
} from '@tixlabs/grpc-client/web-partner';
import { useFareCheck } from '@web-booker/hooks/apps';
import { useCurrency } from '@web-booker/hooks/internals';
import { TObjectCalcPriceTicket } from '@web-booker/types';
import { useEffect } from 'react';
import PriceSegmentInfo from './PriceSegmentInfo';
import { convertHubSegmentToSegment } from '@web-booker/utils';

type Props = {
  data: IFlightInfo;
  activeIndex: number;
  flightIndex: number;
  isShow?: boolean;
  // passengerQuantity: IPassengerQuantity;

  // calcPrice: (classesData: IFlightClass) => CalculatePriceResultType;
};

function PriceItem({
  label,
  fee,
  pricePerOne,
  serviceFee,
  tax,
  tickets,
  discount,
  vat,
  totalPrice,
}: { label: string; totalPrice: number } & TObjectCalcPriceTicket) {
  const { formatPrice } = useCurrency();
  return (
    <tr className='border-b'>
      <td align='left'>
        {label} x {tickets}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(pricePerOne)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(tax + fee)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(vat || 0)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(serviceFee)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(totalPrice)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(totalPrice * tickets)}
      </td>
    </tr>
  );
}

function PriceSegmentInfoByClasses({
  data,
  currentClass,
}: {
  data: ISegment;
  currentClass: IFlightClass;
}) {
  const cabinClassList = currentClass.cabinClass.split(',');

  return (
    <div className=' rounded border  border-neutral-5 overflow-hidden'>
      <div className='text-sm flex items-center gap-x-[5px] px-2.5 py-1.5 rounded bg-neutral-5'>
        <span>Chặng bay</span>
        <span className='text-neutral-10 font-semibold'>
          {data.departPlace}
        </span>
        <PlaneFillIcon className='w-4 h-4 text-neutral-7 rotate-90' />{' '}
        <span className='text-neutral-10 font-semibold'>
          {data.arrivalPlace}
        </span>
      </div>
      <div className=' bg-neutral-1 grid grid-cols-[3fr,2fr] divide-x'>
        <div className='grid grid-cols-2 grid-rows-2 gap-1 px-2.5 py-4'>
          {[
            {
              label: 'Hạng chỗ',
              Icon: SeatClassIcon,
              value: currentClass.bookingClass,
            },
            {
              label: 'Fare basis',
              Icon: DollarIcon,
              value: currentClass.fareBasis,
            },
            {
              label: '',
              Icon: null,
              value: '',
            },
            {
              label: 'Khoang',
              Icon: PlaneFillIcon,
              value: (
                <span title={currentClass.cabinClass}>
                  {cabinClassList.length > 1
                    ? `${cabinClassList[0]},...`
                    : cabinClassList[0]}
                </span>
              ),
            },
          ].map(({ Icon, label, value }, index) => (
            <div
              key={index}
              className='flex flex-nowrap gap-x-[5px] items-center text-sm text-neutral-10 leading-4'>
              {Icon && <Icon className='w-4 h-4 text-neutral-7 shrink-0' />}
              {label && (
                <span className='whitespace-nowrap min-w-[90px]'>
                  {label}:{' '}
                </span>
              )}
              <span className='whitespace-nowrap font-bold'>{value}</span>
            </div>
          ))}
        </div>
        <div className='grid grid-cols-1 grid-rows-2 gap-1 px-2.5 py-4'>
          {[
            {
              label: 'Hành lý ký gửi',
              Icon: BaggageIcon,
              value: <span className='text-red-5 font-normal'>N/A</span>,
            },
            {
              label: 'Hành lý xách tay',
              Icon: BaggageHandIcon,
              value: <span className='text-red-5 font-normal'>N/A</span>,
            },
          ].map(({ Icon, label, value }, index) => (
            <div
              key={index}
              className='flex flex-nowrap gap-x-[5px] items-center text-sm text-neutral-10 leading-4'>
              <Icon className='w-4 h-4 text-neutral-7 shrink-0' />
              <span className='whitespace-nowrap min-w-[130px]'>{label}: </span>
              <span className='whitespace-nowrap font-bold'>{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function FlightPriceInfo({
  data,
  activeIndex,
  isShow,
  flightIndex,
}: Props) {
  // const { formatPrice, currentCurrency } = useCurrency();

  const { fetchFareCheck, fareCheckData, isLoading } = useFareCheck();

  const currentClass = data.classesList[activeIndex];
  const itineraryData = fareCheckData?.itinerariesList?.[flightIndex];

  useEffect(() => {
    // console.log('change ???');
    if (
      currentClass &&
      isShow &&
      itineraryData?.id !== currentClass.itineraryId
    ) {
      fetchFareCheck(currentClass);
    }
  }, [currentClass, isShow]);

  return (
    <Card
      classNameTitle='!px-5'
      className='border-none'
      icon={<Refund2LineIcon className='w-6 h-6 text-neutral-7' />}
      title='Thông tin giá vé'>
      <div className='p-5 space-y-2.5'>
        {isLoading
          ? data.segmentsList.map((item, index) => (
              <div key={index} className='overflow-hidden duration-300'>
                <div className='relative h-8 flex animate-pulse justify-between overflow-hidden bg-neutral-4 duration-300 '></div>
                <div className='relative h-[100px] flex animate-pulse justify-between overflow-hidden bg-neutral-3 duration-300 '></div>
              </div>
            ))
          : fareCheckData
          ? itineraryData?.segmentsList?.map((segmentItem, index) => (
              <PriceSegmentInfo
                hubItinerary={itineraryData}
                segmentInfo={convertHubSegmentToSegment(segmentItem)}
                key={`${itineraryData.id}_${index}`}
              />
            ))
          : data.segmentsList.map((item, index) => (
              <PriceSegmentInfoByClasses
                currentClass={currentClass}
                key={`${currentClass.flightId}_${index} `}
                data={item}
              />
            ))}

        {/* <table className='table-auto w-full view-table'>
          <thead>
            <tr>
              <th align='left'>Hành khách</th>
              <th align='right'>Giá vé</th>
              <th align='right'>Thuế và phí</th>
              <th align='right'>VAT</th>
              <th align='right'>Phí dịch vụ</th>
              <th align='right'>Tổng 1 khách</th>
              <th align='right'>Tổng giá</th>
            </tr>
          </thead>
          <tbody>
            {passengerQuantity.adt > 0 && (
              <PriceItem
                label='Người lớn'
                fee={0} //{currentClass.adtFee}
                pricePerOne={0} //{currentClass.adtPrice}
                serviceFee={0} //{currentClass.adtServiceFee}
                tax={0} //{currentClass.adtTax}
                vat={0} //{currentClass.adtVat}
                tickets={passengerQuantity.adt}
                totalPrice={priceData.eachAdt}
              />
            )}
            {passengerQuantity.chd > 0 && (
              <PriceItem
                label='Trẻ em'
                fee={0} //{currentClass.chdFee}
                pricePerOne={0} //{currentClass.chdPrice}
                serviceFee={0} //{currentClass.chdServiceFee}
                tax={0} //{currentClass.chdTax}
                vat={0} //{currentClass.chdVat}
                tickets={passengerQuantity.chd}
                totalPrice={priceData.eachChd}
              />
            )}
            {passengerQuantity.inf > 0 && (
              <PriceItem
                label='Em bé'
                fee={0} //{currentClass.infFee}
                pricePerOne={0} //{currentClass.infPrice}
                serviceFee={0} //{currentClass.infServiceFee}
                tax={0} //{currentClass.infTax}
                vat={0} //{currentClass.infVat}
                tickets={passengerQuantity.inf}
                totalPrice={priceData.eachInf}
              />
            )}
          </tbody>
          <tfoot>
            <tr className='text-neutral-8'>
              <th align='right' colSpan={9}>
                <div className='inline-flex items-center gap-x-[5px]'>
                  <span className='font-semibold'>Tổng giá vé:</span>
                  <span className='font-bold text-xl text-secondary-6 leading-6'>
                    {formatPrice(priceData.totalPrice)}
                  </span>
                  <span>{currentCurrency.text}</span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table> */}
      </div>
    </Card>
  );
}

export default FlightPriceInfo;
