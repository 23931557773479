import {
  Card,
  FormInput,
  FormPhoneCode,
  FormSelect,
  GroupFillIcon,
} from '@common-ui';

import {
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '@web-booker/utils';
import { PRONOUN_OPTIONS } from '@web-booker/utils/constants/booking';

type Props = {
  //
};

const InputContactInfoCtn = (props: Props) => {
  return (
    <Card
      title='Thông tin liên hệ'
      icon={<GroupFillIcon />}
      classNameChildren='px-3 py-2.5 flex flex-col space-y-5'>
      <div
        style={{
          gridTemplateColumns: '1fr 153px 1fr 389px',
        }}
        className='grid grid-cols-4 grid-flow-row-dense gap-2.5'>
        <div className=''>
          <FormSelect
            name='contactInfo.title'
            selectOptions={PRONOUN_OPTIONS}
            label='Danh xưng'
            placeholder='Chọn'
            labelProps={{ isRequired: true }}
            rules={{ required: 'Chọn danh xưng' }}
            inputProps={{
              isOnlyValue: true,
            }}
          />
        </div>
        <div className=''>
          <FormInput
            name='contactInfo.lastName'
            label='Họ'
            placeholder='Nhập họ'
            labelProps={{ isRequired: true }}
            inputProps={{ forceUpperCase: true }}
            rules={{
              required: 'Nhập họ',
              validate: {
                name: (v) => validateName(v),
              },
            }}
          />
        </div>
        <div className=''>
          <FormInput
            name='contactInfo.firstName'
            label='Tên'
            placeholder='Nhập tên'
            labelProps={{ isRequired: true }}
            inputProps={{ forceUpperCase: true }}
            rules={{
              required: 'Nhập tên',
              validate: {
                name: (v) => validateName(v),
              },
            }}
          />
        </div>
        <div className=''>
          <FormInput
            name='contactInfo.address'
            label='Địa chỉ'
            placeholder='Nhập địa chỉ'
          />
        </div>
        <div className='col-span-1'>
          <FormPhoneCode
            name='contactInfo.phoneCode'
            label='Mã vùng'
            placeholder='Chọn mã vùng'
            labelProps={{ isRequired: true }}
            rules={{ required: 'Chọn mã vùng' }}
          />
        </div>
        <div className='col-span-2'>
          <FormInput
            name='contactInfo.phoneNumber'
            label='Điện thoại'
            placeholder='Nhập số điện thoại'
            labelProps={{ isRequired: true }}
            inputProps={{
              customFormat(value) {
                return value.replace(/[^0-9]/g, '');
              },
              minLength: 4,
              maxLength: 13,
            }}
            rules={{
              required: 'Nhập số điện thoại',
              validate: {
                phoneNumber: (v) => validatePhoneNumber(v),
              },
            }}
          />
        </div>
        <div className=''>
          <FormInput
            name='contactInfo.email'
            label='Email'
            placeholder='Nhập email'
            labelProps={{ isRequired: true }}
            rules={{
              required: 'Nhập email',
              validate: {
                email: (v) => validateEmail(v),
              },
            }}
          />
        </div>
        <div className='col-span-4'>
          <FormInput
            name='contactInfo.note'
            label='Ghi chú'
            placeholder='Nhập ghi chú'
          />
        </div>
      </div>
    </Card>
  );
};

export default InputContactInfoCtn;
