import { IHubItinerary } from '@tixlabs/grpc-client';
import {
  IFlightClassData,
  IFlightInfo,
  IPaxFare,
  ISearchFlightFilter,
  ISegment,
} from '@tixlabs/grpc-client/web-partner';

import { EItineraryType, TObjectCalcPriceTicket } from '@web-booker/types';

export const getStopNumTitle = (stopNum: number) => {
  return stopNum - 1 === 0 ? 'Bay thẳng' : `${stopNum - 1} điểm dừng`;
};

export const convertTimeRangeToValue = (from: number, to: number) => {
  // convert hour to minute
  return {
    from: from * 60,
    to: to * 60,
  };
};

export const calculatePrice = (obj: TObjectCalcPriceTicket) => {
  const totalAmount =
    obj.tickets *
    (obj.pricePerOne +
      obj.fee +
      obj.tax +
      (obj.vat || 0) +
      obj.serviceFee -
      (obj.discount || 0));
  return totalAmount;
};
export type CalculatePriceResultType = {
  totalPrice: number;

  eachAdt: number;
  eachChd: number;
  eachInf: number;

  totalAdt: number;
  totalChd: number;
  totalInf: number;
};

export function convertPaxFareToCalcPriceTickets(
  data: IPaxFare | undefined,
  rest: Partial<TObjectCalcPriceTicket>
): TObjectCalcPriceTicket {
  const vat =
    ((rest.tickets || 1) *
      (rest.vat || 0) *
      ((data?.fareBasic || 0) + (data?.taxAmount || 0))) /
    100;
  return {
    ...rest,
    tickets: rest.tickets || 1,
    pricePerOne: data?.fareBasic || 0,
    tax: data?.taxAmount || 0,
    fee: 0,
    serviceFee: data?.serviceFee || rest.serviceFee || 0,
    discount: 0,
    vat,
  };
}

export const getItineraryType = (
  data: ISearchFlightFilter['flightsList']
): EItineraryType | null => {
  if (!data.length) {
    return null;
  }

  if (data.length === 1) {
    return EItineraryType.ONE_WAY;
  }

  if (data.length === 2) {
    if (
      data[0].startPoint === data[1].endPoint &&
      data[1].startPoint === data[0].endPoint
    ) {
      return EItineraryType.ROUND_TRIP;
    }
  }

  return EItineraryType.MULTIPLE_TRIP;
};

export function convertHubSegmentToSegment(
  data: IHubItinerary['segmentsList'][number]
): ISegment {
  return {
    aircraft: data.flightInfo?.aircraft || '',
    carrierMarketing: data.flightInfo?.carrierMarketing || '',
    carrierOperator: data.flightInfo?.carrierOperator || '',
    flightNumber: data.flightInfo?.flightNumber || '',
    arrivalTerminal: '',
    departTerminal: '',
    flightDuration: data.flightDuration,

    departPlace: data.routing?.departPlace || '',
    departDate: data.routing?.departDate || 0,
    arrivalPlace: data.routing?.arrivalPlace || '',
    arrivalDate: data.routing?.arrivalDate || 0,
    index: data.index,
  };
}

export function getFlightId(flightDataList: IFlightInfo[]) {
  try {
    if (!flightDataList.length) return '';

    const lastFlight = flightDataList[flightDataList.length - 1];
    const prevFlight = flightDataList[flightDataList.length - 2];

    if (!prevFlight) {
      return lastFlight.classesList[0].flightsList[0].flightId;
    }

    return (
      lastFlight.classesList[0].flightsList.find(
        (fItem) =>
          fItem.prevItineraryClass === getPrevItineraryClassChain(prevFlight)
      )?.flightId || ''
    );
  } catch (error) {
    console.error("Can't find flight id", error);
    return '';
  }
}

function getPrevItineraryClassChain(flightData: IFlightInfo): string {
  if (!flightData.classesList[0]?.validFlightClassData?.prevItineraryClass) {
    return (
      flightData.classesList[0]?.validFlightClassData?.itineraryClass || ''
    );
  }

  return [
    flightData.classesList[0]?.validFlightClassData?.prevItineraryClass,
    flightData.classesList[0]?.validFlightClassData?.itineraryClass,
  ].join('|');
}

export function getMinPriceFlightClassData(
  flightList: IFlightClassData[],
  prevFlightData?: IFlightInfo
) {
  const filterFlightList = prevFlightData
    ? flightList.filter(
        (fItem) =>
          fItem.prevItineraryClass ===
          getPrevItineraryClassChain(prevFlightData)
      )
    : flightList;

  let result = filterFlightList[0];

  filterFlightList.forEach((item) => {
    if (item.totalFareAmount <= result.totalFareAmount) {
      result = { ...item };
    }
  });

  return result;
}

// export function getValidPriceFlightClassData(
//   flightList: IFlightClassData[],
//   prevFlightData: IFlightInfo
// ) {
//   const findFlight = flightList.filter(
//     (fItem) =>
//       fItem.prevItineraryClass ===
//       prevFlightData.classesList[0]?.validFlightClassData?.itineraryClass
//   );

//   const findExactFlight = findFlight.find(
//     (fItem) =>
//       fItem.flightId ===
//       prevFlightData.classesList[0].validFlightClassData?.flightId
//   );

//   // const findSamePrice = findFlight.find(
//   //   (fItem) =>
//   //     fItem.totalFareAmount ===
//   //     prevFlightData.classesList[0].validFlightClassData?.totalFareAmount
//   // );

//   // if (findExactFlight) {
//   //   console.log('findExactFlight', findExactFlight);
//   // } else {
//   //   console.log(
//   //     'same price',
//   //     findFlight.find(
//   //       (fItem) =>
//   //         fItem.totalFareAmount ===
//   //         prevFlightData.classesList[0].validFlightClassData?.totalFareAmount
//   //     )
//   //   );
//   //   console.log(
//   //     'same class',
//   //     findFlight.find(
//   //       (fItem) =>
//   //         fItem.totalFareAmount ===
//   //         prevFlightData.classesList[0].validFlightClassData?.totalFareAmount
//   //     )
//   //   );
//   // }

//   return findExactFlight || findFlight[0];
// }

export function customFlightInfo(
  flightData: IFlightInfo,
  prevFlightData?: IFlightInfo
): IFlightInfo {
  try {
    if (!prevFlightData || flightData.isFirstFlight) {
      return {
        ...flightData,
        classesList: flightData.classesList
          .map((item) => ({
            ...item,
            validFlightClassData: getMinPriceFlightClassData(item.flightsList),
          }))
          .sort(
            (a, b) =>
              a.validFlightClassData.displayPrice -
              b.validFlightClassData.displayPrice
          ),
      };
    }

    return {
      ...flightData,
      classesList: flightData.classesList
        .filter((item) =>
          item.flightsList.some(
            (fItem) =>
              fItem.prevItineraryClass ===
              getPrevItineraryClassChain(prevFlightData)
          )
        )
        .map((item) => ({
          ...item,
          validFlightClassData: getMinPriceFlightClassData(
            item.flightsList,
            prevFlightData
          ),
        }))
        .sort(
          (a, b) =>
            (a.validFlightClassData?.displayPrice || 0) -
            (b.validFlightClassData?.displayPrice || 0)
        ),
    };
  } catch (error) {
    console.trace('ERROR:', error);
    return flightData;
  }
}
