import {
  IFlightInfo,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { updateCalculationFlightFee } from '@web-booker/stores/reducers/booking';
import { VN_COUNTRY_CODE, getFlightId } from '@web-booker/utils';
import { useMemo } from 'react';
import { useAppMutation } from '../internals';
import useAirport from '../stores/useAirport';

const useBooking = () => {
  const dispatch = useAppDispatch();
  const { airportsObjectByCode } = useAirport();

  const { key } = useAppSelector(
    (state) => state.searchFlight.searchDataHistoryFullData
  );

  const {
    passengerQuantity,
    bookingInfo: { flightDataList, sessionId, bookingCode, expiredAt },
    contactInfo,
    passengersList,
    calculatedFlightFee,
    invoicingInformation,
    isInvoiceInfo,
  } = useAppSelector((state) => state.booking);

  const { mutateAsync: calculationFlightFee } = useAppMutation({
    mutationKey: ['flightApiService', 'calculationFlightFee'],
    mutationFn: flightApiService.calculationFlightFee,
  });

  const handleCalculateFlightFee = async () => {
    try {
      const flightId = getFlightId(flightDataList);

      if (!flightId) {
        throw new Error("Can't found flight");
      }

      const { isSuccess, errorCode, data } = await calculationFlightFee({
        flightId,
        key,
        passengerQuantity,
      });
      if (isSuccess && data) {
        dispatch(updateCalculationFlightFee(data));
      }
      throw new Error(errorCode);
    } catch (error) {}
  };

  const isOnlyDomesticBooking = useMemo(() => {
    const airPortList = flightDataList.reduce((arr: string[], cur) => {
      return [...arr, cur.departPlace, cur.arrivalPlace];
    }, []);

    if (airportsObjectByCode) {
      return airPortList.every(
        (airPort) =>
          airportsObjectByCode[airPort]?.countryCode === VN_COUNTRY_CODE
      );
    }
    return false;
  }, [airportsObjectByCode]);

  return {
    expiredAt,
    sessionId,
    bookingCode,
    passengerQuantity,
    passengersList,
    contactInfo,
    flightDataList: flightDataList,
    isOnlyDomesticBooking: isOnlyDomesticBooking,
    calculatedFlightFee,
    handleCalculateFlightFee,
    isInvoiceInfo,
    invoicingInformation,
  };
};

export default useBooking;
