import React, { useMemo, useState } from 'react';
import FormSelectSearch from './FormSelectSearch';
import { useCountry } from '@web-booker/hooks/apps';
import { SelectOptionItem } from '@tixlabs/types';
import { TextFieldProps } from './TextField';
import { SelectSearchProps } from '../UI/SelectSearch';
import { useClientPagination } from '@core/hooks';

export const FormCountry = ({
  inputProps = {},
  displayFlag = true,
  displayCode = true,
  displayName = true,
  ...rest
}: TextFieldProps & {
  inputProps?: Pick<
    SelectSearchProps,
    | 'className'
    | 'disabled'
    | 'inputSize'
    | 'inputSearchClassName'
    | 'optionSelectSearchClassName'
    | 'optionGroupSelectSearchClassName'
    | 'isOnlyValue'
    | 'isRoot'
    | 'renderLabel'
    | 'displayValue'
    | 'compareFunc'
  >;
} & {
  displayFlag?: boolean;
  displayCode?: boolean;
  displayName?: boolean;
}) => {
  const { countryNameOptionsValueCode } = useCountry();
  const [countryOptions, setCountryOptions] = useState<SelectOptionItem[]>(
    countryNameOptionsValueCode
  );

  const dataListCountry = useMemo(
    () => countryOptions.slice(0, 50),
    [countryOptions]
  );

  const handleSearch = (searchKeyword: string) => {
    setCountryOptions(
      countryNameOptionsValueCode.filter((country) => {
        return (
          country.label.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          country.value
            .toString()
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
        );
      })
    );
  };

  return (
    <FormSelectSearch
      {...rest}
      handleSearch={handleSearch}
      selectOptions={dataListCountry}
      inputProps={{ isOnlyValue: true }}
    />
  );
};

export default FormCountry;
