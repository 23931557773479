import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBaggage } from '@tixlabs/types';

export type TBaggageEachFlight = { flightId: string; listBaggage: IBaggage[] };

type additionalBaggageSliceType = {
  baggageEachFlight: TBaggageEachFlight[];
};

const initialState: additionalBaggageSliceType = {
  baggageEachFlight: [
    {
      flightId:
        '1ace6e6e-7c61-454a-8ce0-30eb0fbbdd14||95f0384f-81bd-4a9e-af8e-729ddaa0a557',
      listBaggage: [
        {
          airline: 'VJ',
          value: 'Value 1',
          code: 'Code 1',
          name: 'SGN-HAN 1',
          price: 100,
          currency: 'vnd',
          startPoint: 'SGN',
          endPoint: 'HAN',
          route: 'Route 1',
          leg: 1,
          statusCode: 'Status 1',
          confirmed: true,
          id: 'ID 1',
        },
        {
          airline: 'VJ',
          value: 'Value 2',
          code: 'Code 2',
          name: 'SGN-HAN 2',
          price: 200,
          currency: 'vnd',
          startPoint: 'SGN',
          endPoint: 'HAN',
          route: 'Route 2',
          leg: 2,
          statusCode: 'Status 2',
          confirmed: false,
          id: 'ID 2',
        },
      ],
    },
    {
      flightId:
        '392fc9a8-822b-49e4-a876-345b0efd532c||19e58e52-214b-419c-9fe6-2f78122594f9',
      listBaggage: [
        {
          airline: 'VN',
          value: 'Value 1',
          code: 'Code 1',
          name: 'VCS - SGN',
          price: 100,
          currency: 'vnd',
          startPoint: 'VCS',
          endPoint: 'SGN',
          route: 'Route 1',
          leg: 1,
          statusCode: 'Status 1',
          confirmed: true,
          id: 'ID 3',
        },
        {
          airline: 'VN',
          value: 'Value 2',
          code: 'Code 2',
          name: 'SGN - DAD',
          price: 200,
          currency: 'vnd',
          startPoint: 'SGN',
          endPoint: 'DAD',
          route: 'Route 2',
          leg: 2,
          statusCode: 'Status 2',
          confirmed: false,
          id: 'ID 4',
        },
      ],
    },
  ],
};

const additionalBaggageSlice = createSlice({
  name: 'additionalBaggageSlice',
  initialState,
  reducers: {
    updateListBaggage(state, action: PayloadAction<TBaggageEachFlight[]>) {
      if (action.payload.length) {
        state.baggageEachFlight = action.payload;
      }
    },
  },
});

export const { updateListBaggage } = additionalBaggageSlice.actions;
export default additionalBaggageSlice.reducer;
