import { IAirportInfo } from '@tixlabs/grpc-client/web-partner';

import { IPassengerQuantity } from '@tixlabs/grpc-client/web-partner';

import { EItineraryType } from './flight-enum';
import { TFromToDate } from '@common-ui';

export interface ISearchFlightInfo {
  startPoint: IAirportInfo | null;
  endPoint: IAirportInfo | null;
  departDate: Date | string | null;
}

export interface IFormSearchFlight {
  itineraryType: EItineraryType;
  passengerQuantity: IPassengerQuantity;
  flightList: ISearchFlightInfo[];
  searchDate: TFromToDate;
  onlyDirectFlight: boolean;
  oneWayAndRoundField: ISearchFlightInfo;
}

export interface IFormFilterFlight {
  displayMode: EFilterDisplayMode;
  airlines: {
    label: string;
    value: string;
    // price: number;
    isSelected: boolean;
  }[];
  stopPointType: {
    label: string;
    value: EStopPointType;
    isSelected: boolean;
  }[];
  ticketClass: {
    label: string;
    value: string;
    isSelected: boolean;
  }[];
  takeOffRanges: {
    label: string;
    durationLabel: string;
    value: {
      from: number;
      to: number;
    };
    isSelected: boolean;
  }[];
  landingTimes: {
    label: string;
    durationLabel: string;
    value: {
      from: number;
      to: number;
    };
    isSelected: boolean;
  }[];
}

export enum ESortType {
  AIR_LINE,
  DEPART_DATE,
  ARRIVAL_DATE,
  TICKET_PRICE,
}

export enum EFilterDisplayMode {
  TOTAL_PASSENGER = 'TOTAL_PASSENGER',
  BASIC_ADT = 'BAIC_ADT',
  TOTAL_PER_ADT = 'TOTAL_PER_ADT',
}

export interface IFormSortFlight {
  sortsList: {
    label: string;
    type: ESortType;
    asc: boolean;
    isSelected: boolean;
  }[];
}

export interface IFlightServiceFee {
  vat: number;
  serviceFee: number;
}

export enum EStopPointType {
  STOP_POINT_TYPE_NONE = 0,
  STOP_POINT_TYPE_NONE_STOP = 1,
  STOP_POINT_TYPE_ONE = 2,
  STOP_POINT_TYPE_OVER_TWO = 3,
}
