import { Card, FlightTakeOffIcon } from '@common-ui';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useCurrency } from '@web-booker/hooks/internals';
import { useEffect } from 'react';
import PriceTicketInfo from './components/PriceTicketInfo';
import SelectedFlightItem from './components/SelectedFlightItem';

type Props = {
  showInfo?: boolean;
};

export const SelectedFlightsInfoCtn = ({ showInfo }: Props) => {
  const { formatPrice, currency } = useCurrency();
  const {
    flightDataList,
    handleCalculateFlightFee,
    passengerQuantity,
    calculatedFlightFee,
  } = useBooking();

  useEffect(() => {
    if (showInfo) {
      handleCalculateFlightFee();
    }
  }, [showInfo]);

  if (!flightDataList.length) {
    return null;
  }

  return (
    <div id='selected-flight-info'>
      <Card
        icon={<FlightTakeOffIcon />}
        title={'Chuyến bay đã chọn'}
        classNameChildren='p-2.5 flex flex-col space-y-5'>
        <div className='flex flex-col space-y-2.5'>
          {flightDataList.map((item, index) => {
            return (
              <SelectedFlightItem
                key={index}
                index={index}
                data={item}
                passengerQuantity={passengerQuantity}
              />
            );
          })}
        </div>
        {showInfo && (
          <div className='space-y-2.5'>
            <PriceTicketInfo data={calculatedFlightFee} />
            <div className='flex justify-end font-semibold items-center space-x-2'>
              <span>Tổng giá vé:</span>
              <span className='font-bold text-xl text-secondary'>
                {formatPrice(calculatedFlightFee.totalPrice)}
              </span>
              <span>{currency}</span>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default SelectedFlightsInfoCtn;
