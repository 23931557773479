import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ICalculationFlightFeeRes,
  IInvoicingInformation,
} from '@tixlabs/grpc-client';
import {
  IFlightInfo,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';
import {
  IBookingFlightContactInfo,
  IBookingFlightPassenger,
} from '@tixlabs/types';
import { IPassengerForm } from '@web-booker/types/ui/booking';

type TBookingInfo = {
  flightDataList: IFlightInfo[];
  sessionId: string;
  orderId: string;
  bookingId: string;
  bookingCode: string;
  expiredAt: number;
  // flightIdsList: string[];
  // itineraryType: EItineraryType;
};

interface IBookingState {
  passengersList: IBookingFlightPassenger[];
  contactInfo: IBookingFlightContactInfo;
  passengerQuantity: IPassengerQuantity;
  bookingInfo: TBookingInfo;
  calculatedFlightFee: ICalculationFlightFeeRes;
  invoicingInformation: IInvoicingInformation;
  isInvoiceInfo: boolean;
}

const initialState = {
  passengersList: [],
  contactInfo: {
    title: '',
    firstName: '',
    lastName: '',
    phoneCode: '+84',
    phoneNumber: '',
    email: '',
    address: '',
    note: '',
  },
  passengerQuantity: {
    adt: 1,
    chd: 0,
    inf: 0,
  },
  bookingInfo: {
    sessionId: '',
    orderId: '',
    bookingId: '',
    bookingCode: '',
    expiredAt: 0,
    flightDataList: [],
  },
  calculatedFlightFee: {
    profit: 0,
    totalPrice: 0,
    itemsList: [],
    vat: 0,
    vatConfig: 0,
  },
  invoicingInformation: {
    companyInfo: {
      address: '',
      name: '',
      taxCode: '',
    },
    receiverInfo: {
      address: '',
      email: '',
      name: '',
      note: '',
      phone: '',
    },
  },
  isInvoiceInfo: false,

  // contactInfo: {
  //   title: 'mr',
  //   firstName: 'ho',
  //   lastName: 'Phu',
  //   phoneCode: '+84',
  //   phoneNumber: '708209197',
  //   email: 'sp@gmail.com',
  //   address: '325 bac hdang',
  //   note: 'note',
  // },
  // invoicingInformation: {
  //   companyInfo: {
  //     address: '325 bbbb',
  //     name: 'company x',
  //     taxCode: '123123412',
  //   },
  //   receiverInfo: {
  //     address: 'dia chi ne',
  //     email: 'sp@gmail.com',
  //     name: 'name nguoi nhan ne',
  //     note: 'noite nè',
  //     phone: '070820929287',
  //   },
  // },
} as IBookingState;

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    updatePassengersList: (state, action: PayloadAction<IPassengerForm[]>) => {
      if (action.payload.length) {
        const clonePayload = action.payload.map((passenger) => {
          const dob = passenger.dob ? passenger.dob.getTime() : 0;
          const expirationDate = passenger.expirationDate
            ? passenger.expirationDate.getTime()
            : 0;
          return {
            ...passenger,
            dob,
            expirationDate,
          };
        });
        state.passengersList = clonePayload;
      }
    },
    updatePassengerQuantity: (
      state,
      action: PayloadAction<IPassengerQuantity>
    ) => {
      state.passengerQuantity = action.payload;
    },
    updateContactInfo: (
      state,
      action: PayloadAction<IBookingFlightContactInfo>
    ) => {
      state.contactInfo = action.payload;
    },
    // updatePassengerBaggage(
    //   state,
    //   action: PayloadAction<{
    //     id: string;
    //     indexPassenger: number;
    //     indexBaggageId: number;
    //   }>
    // ) {
    //   if (state.passengersList?.length) {
    //     state.passengersList[action.payload.indexPassenger].baggagesList[
    //       action.payload.indexBaggageId
    //     ].id = action.payload.id;
    //   }
    // },
    addBookingFlight(
      state,
      action: PayloadAction<{
        flightDetail: IFlightInfo;
        sessionId: string;
        index: number;
      }>
    ) {
      // index
      if (state.bookingInfo?.sessionId === action.payload.sessionId) {
        // Check not select previous option
        if (
          !state.bookingInfo.flightDataList.some(
            (item) =>
              item.classesList[0].itineraryId ===
              action.payload.flightDetail.classesList[0].itineraryId
          )
        ) {
          const currentFlight =
            state.bookingInfo.flightDataList?.[action.payload.index];
          // Check edit case
          if (currentFlight) {
            state.bookingInfo = {
              ...state.bookingInfo,
              flightDataList: [
                ...state.bookingInfo.flightDataList.slice(
                  0,
                  action.payload.index
                ),
                action.payload.flightDetail,
              ],
              sessionId: action.payload.sessionId,
            };
          } else {
            state.bookingInfo.flightDataList[action.payload.index] =
              action.payload.flightDetail;
          }
        }
      } else {
        state.bookingInfo = {
          ...state.bookingInfo,
          flightDataList: [action.payload.flightDetail],
          sessionId: action.payload.sessionId,
        };
      }
    },
    updateBookingInfo: (
      state,
      action: PayloadAction<Partial<TBookingInfo>>
    ) => {
      Object.keys(state.bookingInfo).forEach((key) => {
        if (action.payload[key]) {
          state.bookingInfo[key] = action.payload[key];
        }
      });
    },
    updateCalculationFlightFee: (
      state,
      action: PayloadAction<ICalculationFlightFeeRes>
    ) => {
      state.calculatedFlightFee = action.payload;
    },
    updateInvoicingInformation: (
      state,
      action: PayloadAction<IInvoicingInformation>
    ) => {
      state.invoicingInformation = action.payload;
      state.isInvoiceInfo = true;
    },
    updateInvoicing: (state, action: PayloadAction<boolean>) => {
      state.isInvoiceInfo = action.payload;
    },
    resetBooking() {
      return { ...initialState };
    },
    // resetBooking() {
    //   return { ...initialState };
    // },
  },
});

export const {
  updatePassengersList,
  updatePassengerQuantity,
  updateContactInfo,
  updateCalculationFlightFee,
  updateInvoicingInformation,
  addBookingFlight,
  updateBookingInfo,
  resetBooking,
  updateInvoicing,
} = bookingSlice.actions;
export default bookingSlice.reducer;
