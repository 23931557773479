import {
  IBookingFlightData,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppSelector } from '@web-booker/stores';
import { IFormBooking } from '@web-booker/types/ui/booking';
import { useAppMutation, useToast } from '../internals';
import { useState } from 'react';
import { getApiErrorMessages } from '@core/utils';
import { getFlightId } from '@web-booker/utils';

export const useValidateBooking = () => {
  const toast = useToast();

  const [validateResponse, setValidateResponse] =
    useState<IBookingFlightData>();

  const { key } = useAppSelector(
    (state) => state.searchFlight.searchDataHistoryFullData
  );

  const {
    bookingInfo: { flightDataList, sessionId },
    calculatedFlightFee,
  } = useAppSelector((state) => state.booking);

  const { mutateAsync: validateInfo, isLoading: isLoadingValidate } =
    useAppMutation({
      mutationKey: ['flightApiService', 'bookingFlight'],
      mutationFn: flightApiService?.bookingFlight,
    });

  const validateBooking = async (
    data: IFormBooking,
    handleSuccess: (data: IBookingFlightData) => void,
    handlePriceChanged: () => void,
    handleInvalidInfo: () => void
  ) => {
    const passengerListConverted = data.passengersList.map((passenger) => ({
      ...passenger,
      dob: new Date(passenger.dob as Date).getTime(),
      expirationDate:
        passenger.expirationDate && passenger.expirationDate !== null
          ? new Date(passenger.expirationDate).getTime()
          : undefined,
    }));
    try {
      const flightId = getFlightId(flightDataList);

      const {
        isSuccess,
        errorCode,
        data: response,
      } = await validateInfo({
        currency: 'VND',
        flightId,
        isPublic: false,
        key: key,
        note: '',
        contactInfo: data.contactInfo,
        sessionId: sessionId,
        passengersList: passengerListConverted,
        confirmPrice: calculatedFlightFee.totalPrice,
        invoicingInformation: data.isInvoicing
          ? data.invoicingInformation
          : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      if (isSuccess && response) {
        setValidateResponse(response);
        if (
          response?.fee &&
          response.fee.totalPrice !== calculatedFlightFee.totalPrice
        ) {
          handlePriceChanged();
          return;
        }
        handleSuccess(response);
        return;
      }
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const msg = getApiErrorMessages(error);
      if (msg[0] === 'ITINERARY_ALREADY_EXIST_PASSENGER') {
        handleInvalidInfo();
      } else {
        toast.error(msg[0]);
      }
    }
  };

  return {
    isLoadingValidate,
    validateResponse,
    validateBooking,
  };
};

export default useValidateBooking;
