import { Card } from '@common-ui';
import { MoneyDollarCircleFillIcon } from '@icon';
import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';

import { useCurrency } from '@web-booker/hooks/internals';
import BookingPriceTicketInfo from './BookingPriceTicketInfo';

type Props = {
  //
  data: IRetrieveBookingFlightData;
};

export const BookingPriceInfo = ({ data }: Props) => {
  const { formatPrice } = useCurrency();

  return (
    <Card
      title='Giá vé'
      icon={<MoneyDollarCircleFillIcon className='w-5 h-5 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='bg-neutral-4 p-3 flex mt-1.5 rounded '>
      <div className='w-full space-y-2.5'>
        {data.fee && (
          <BookingPriceTicketInfo
            fee={data.fee}
            passengersList={data.passengersList}
          />
        )}
        <div className='flex justify-end items-center gap-x-1'>
          <div className='flex flex-col divide-neutral-5 min-w-[268px]'>
            <div className='flex justify-between py-1 border-b border-b-neutral-5'>
              <span>Tổng cộng</span>
              <span className=''>
                {formatPrice(
                  (data.fee?.totalPrice || 0) - (data.fee?.vat || 0)
                )}
              </span>
            </div>
            <div className='flex justify-between py-1 border-b-2 border-b-neutral-5'>
              <span>Thuế</span>
              <span className=''>{formatPrice(data.fee?.vat || 0)}</span>
            </div>
            <div className='flex justify-between font-bold py-1'>
              <span>Tổng thanh toán:</span>
              <span className='text-secondary-6'>
                {formatPrice(data.fee?.totalPrice || 0)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BookingPriceInfo;
