import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IFlightInfo,
  ISearchFlightFilter,
  ISearchFlightRes,
  ISortItem,
} from '@tixlabs/grpc-client/web-partner';

import { EFlightItineraryType, IPagination } from '@tixlabs/grpc-client';

import { EItineraryType } from '@web-booker/types';
import {
  EFilterDisplayMode,
  ESortType,
  EStopPointType,
} from '@web-booker/types/ui/flight';
import { DEFAULT_FILTER, DEFAULT_PAGE_LIMIT } from '@web-booker/utils';

type ISearchFullData = Pick<
  ISearchFlightRes,
  'itineraryType' | 'itemsList' | 'key'
> & {
  itemMaps?: Map<string, IFlightInfo>;
};

type TFromToTime = {
  from: number;
  to: number;
};
export type TFilterClient = {
  airlines: string[];
  stopPointType: EStopPointType[];
  takeOffRanges: TFromToTime[];
  landingTimes: TFromToTime[];
  displayMode: EFilterDisplayMode;
  ticketClass: string[];
};

export type TSortClient = {
  type: ESortType;
  asc: boolean;
};
interface SearchFlightState {
  isLoading: boolean;
  filterClient: TFilterClient;
  sortClient: TSortClient;
  filter: ISearchFlightFilter;
  pagination: IPagination;
  sortsList: ISortItem[];
  requestCount: number;
  searchDataHistory: ISearchFlightRes[];
  searchDataHistoryFullData: ISearchFullData;

  currentSelectIndex: number;
  itineraryType: EItineraryType;
}

const defaultSearchFullData: ISearchFullData = {
  itemsList: [],
  key: '',
  itineraryType: EFlightItineraryType.INONE,
};

const initialState = {
  isLoading: false,
  filterClient: {
    airlines: [] as string[],
    stopPointType: [] as EStopPointType[],
    takeOffRanges: [] as TFromToTime[],
    landingTimes: [] as TFromToTime[],
    displayMode: EFilterDisplayMode.TOTAL_PASSENGER,
    ticketClass: [] as string[],
  },
  sortClient: {
    type: ESortType.TICKET_PRICE,
    asc: true,
  },
  filter: {
    passengerQuantity: {
      adt: 1,
      chd: 0,
      inf: 0,
    },
    flightsList: [],
  },
  pagination: {
    pageLimit: DEFAULT_PAGE_LIMIT,
    pageNumber: 1,
  },
  requestCount: 0,
  sortsList: [] as ISortItem[],

  searchDataHistory: [] as ISearchFlightRes[],
  searchDataHistoryFullData: defaultSearchFullData,
  currentSelectIndex: -1,
  itineraryType: EItineraryType.ONE_WAY,
} as SearchFlightState;

const searchFlight = createSlice({
  name: 'searchFlight',
  initialState,
  reducers: {
    submitSearchData(
      state,
      action: PayloadAction<{
        filter: Pick<ISearchFlightFilter, 'passengerQuantity' | 'flightsList'>;
        itineraryType: EItineraryType;
      }>
    ) {
      state.filter = {
        ...DEFAULT_FILTER,
        ...action.payload.filter,
      };
      state.itineraryType = action.payload.itineraryType;
      state.searchDataHistory = [];
      state.searchDataHistoryFullData = defaultSearchFullData;
      state.currentSelectIndex = 0;
      // state.filter.passengerQuantity = passengerQuantity;
      // state.filter.flightsList = flightsList;
      // state.filter.stopPointTypesList = stopPointTypesList;
    },
    updateSearchData(
      state,
      action: PayloadAction<{
        filter: Pick<ISearchFlightFilter, 'passengerQuantity' | 'flightsList'>;
        itineraryType: EItineraryType;
      }>
    ) {
      state.filter = {
        ...DEFAULT_FILTER,
        ...action.payload.filter,
      };
      state.itineraryType = action.payload.itineraryType;
    },
    requestData(state) {
      state.requestCount++;
    },
    saveSearchDataHistoryFullData(
      state,
      action: PayloadAction<ISearchFullData>
    ) {
      state.searchDataHistoryFullData = action.payload;
    },
    addSearchDataHistory(state, action: PayloadAction<ISearchFlightRes>) {
      state.searchDataHistory = [...state.searchDataHistory, action.payload];
    },
    updateSearchDataHistory(state, action: PayloadAction<ISearchFlightRes[]>) {
      state.searchDataHistory = action.payload;
    },
    clearSearchHistoryAfterIndex(state, action: PayloadAction<number>) {
      state.searchDataHistory = state.searchDataHistory.slice(
        0,
        action.payload + 1
      );
    },
    clearSearchHistory(state) {
      state.searchDataHistory = [];
      state.searchDataHistoryFullData = defaultSearchFullData;
    },
    updateSelectIndex(state, action: PayloadAction<number>) {
      state.currentSelectIndex = action.payload;
    },
    updateFilterClient(
      state,
      action: PayloadAction<SearchFlightState['filterClient']>
    ) {
      state.filterClient = action.payload;
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateSortClient(
      state,
      action: PayloadAction<SearchFlightState['sortClient']>
    ) {
      state.sortClient = action.payload;
    },
    resetSearchFlight() {
      return { ...initialState };
    },
  },
});

export const {
  submitSearchData,
  requestData,
  addSearchDataHistory,
  updateSelectIndex,
  clearSearchHistoryAfterIndex,
  saveSearchDataHistoryFullData,
  updateFilterClient,
  updateSortClient,
  updateSearchDataHistory,
  updateSearchData,
  resetSearchFlight,
  updateLoading,
} = searchFlight.actions;
export default searchFlight.reducer;
