import {
  HubTransaction,
  TransactionType,
  ListTransactionReq,
} from './wallet_pb';

export type ETransactionType = TransactionType;
export const ETransactionType = { ...TransactionType } as const;

export interface IHubTransaction extends HubTransaction.AsObject {}
export interface IListTransactionReq extends ListTransactionReq.AsObject {}
