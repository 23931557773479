import { Card, GroupFillIcon } from '@common-ui';
import { ConfirmInfoByTypePassenger } from './components/ConfirmInfoByTypePassenger';
import useBooking from '@web-booker/hooks/apps/useBooking';

type Props = {
  //
};

export const ConfirmPassengerInfoCtn = (props: Props) => {
  const { passengersList } = useBooking();
  return (
    <Card
      title='Thông tin hành khách'
      icon={<GroupFillIcon className='w-5 h-5 shrink-0' />}
      isCollapse
      classNameChildren='p-2.5 flex flex-col space-y-2.5'>
      {passengersList?.map((passenger, index) => {
        return (
          <ConfirmInfoByTypePassenger
            key={index}
            indexPassenger={index}
            passengerType={passenger.type}
            passenger={passenger}
          />
        );
      })}
    </Card>
  );
};

export default ConfirmPassengerInfoCtn;
