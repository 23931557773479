import { Button, Form, FormInput } from '@common-ui';
import { validatePasswordByCase } from '@core/utils';
import { CloseIcon } from '@icon';
import {
  IFormChangePassword,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partner';

import { useAppMutation } from '@web-admin/hooks/internals';
import { useForm } from 'react-hook-form';

type Props = {
  //
  onCloseModal: () => void;
  onChangePasswordSuccess: () => void;
};

export const ModalChangePassword = ({
  onCloseModal,
  onChangePasswordSuccess,
}: Props) => {
  const methods = useForm<IFormChangePassword>({
    defaultValues: {
      oldPassword: '',
      password: '',
    },
  });

  const { mutateAsync: changePassword } = useAppMutation({
    mutationKey: ['partnerUserServiceClient', 'changePassword'],
    mutationFn: partnerUserServiceClient.changePassword,
    onSuccess: ({ isSuccess, errorCode }) => {
      //
      if (isSuccess) {
        onChangePasswordSuccess();
      } else {
        // handle show message error by errorCode
        methods.setError(
          'oldPassword',
          {
            message: errorCode,
          },
          {
            shouldFocus: true,
          }
        );
      }
    },
    onError: (error) => {
      // handle case network/system error
    },
  });

  const handleSubmit = async (data) => {
    await changePassword(data);
  };
  return (
    <div className='flex flex-col space-y-6'>
      <div className='flex'>
        <h3 className='text-lg font-semibold text-neutral-11 flex-1'>
          Đổi mật khẩu
        </h3>
        <CloseIcon
          className='w-6 h-6 p-0.5 cursor-pointer text-neutral-7 hover:text-neutral-8'
          onClick={onCloseModal}
        />
      </div>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className='flex flex-col space-y-6'>
        <FormInput
          name='oldPassword'
          label={'Mật khẩu hiện tại'}
          labelProps={{
            className: 'text-neutral-7 font-semibold',
          }}
          inputProps={{
            type: 'password',
          }}
          rules={{
            required: 'Nhập mật khẩu hiện tại',
            minLength: {
              value: 8,
              message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
            },
            validate: {
              checkValid: (v) => {
                const { hasNumeric, hasCharacter } = validatePasswordByCase(v);
                return (
                  (hasNumeric && hasCharacter) ||
                  'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
                );
              },
            },
          }}
          placeholder='Nhập mật khẩu hiện tại'
        />
        <FormInput
          name='password'
          label={'Mật khẩu'}
          labelProps={{
            className: 'text-neutral-7 font-semibold',
          }}
          inputProps={{
            type: 'password',
          }}
          rules={{
            required: 'Nhập mật khẩu mới',
            minLength: {
              value: 8,
              message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
            },
            validate: {
              checkValid: (v) => {
                const { hasNumeric, hasCharacter } = validatePasswordByCase(v);
                const oldPassword = methods.watch('oldPassword');
                if (oldPassword === v) {
                  return 'Mật khẩu mới không được trùng với mật khẩu hiện tại.';
                }
                return (
                  (hasNumeric && hasCharacter) ||
                  'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
                );
              },
            },
          }}
          placeholder='Nhập mật khẩu mới'
        />
        <div className='flex w-full space-x-2.5'>
          <Button theme='neutral' className='flex-1' onClick={onCloseModal}>
            Huỷ bỏ
          </Button>
          <Button className='flex-1' type='submit'>
            Đổi mật khẩu
          </Button>
        </div>
      </Form>
    </div>
  );
};
