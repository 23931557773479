import { Card, Collapse, FlashLightFillIcon, GroupFillIcon } from '@common-ui';
import { useBoolean } from '@web-booker/hooks/internals';
import { IFormBooking } from '@web-booker/types/ui/booking';
import { useFieldArray, useFormContext } from 'react-hook-form';
import GetInfoByTypePassenger from './components/GetInfoByTypePassenger';
import ShortHandInput from './components/ShortHandInput';

type Props = {
  //
};

const InputPassengerInfoCtn = (props: Props) => {
  const { value, toggle } = useBoolean();
  const methods = useFormContext<IFormBooking>();
  const { fields } = useFieldArray({
    control: methods.control,
    name: 'passengersList',
  });
  return (
    <Card
      title={
        <div className='flex items-center gap-x-5 w-full'>
          Thông tin hành khách{' '}
          <span
            onClick={toggle}
            className='text-primary-6 font-semibold inline-flex items-center gap-x-1 cursor-pointer'>
            <FlashLightFillIcon /> NHẬP NHANH
          </span>{' '}
        </div>
      }
      icon={<GroupFillIcon className='w-5 h-5 shrink-0' />}
      defaultShow
      classNameChildren='p-2.5 flex flex-col space-y-2.5'>
      <Collapse panel={<ShortHandInput />} isShow={value} />
      {fields.map((item, index) => {
        return (
          <GetInfoByTypePassenger
            key={item.id}
            indexPassenger={index}
            passengerType={item.type}
            fieldName={`passengersList.${index}`}
          />
        );
      })}
    </Card>
  );
};

export default InputPassengerInfoCtn;
