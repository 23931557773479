import { Card, MoneyDollarCircleFillIcon } from '@common-ui';
import { ICalculationFlightFeeRes } from '@tixlabs/grpc-client';
import { IBookingPassenger } from '@tixlabs/grpc-client/web-partner';
import { useCurrency } from '@web-booker/hooks/internals';
import { useMemo } from 'react';

type Props = {
  fee: ICalculationFlightFeeRes;
  passengersList: IBookingPassenger[];
};

export const AggregateOrder = ({ fee, passengersList }: Props) => {
  const { formatPrice } = useCurrency();

  const calcPrice = useMemo(() => {
    const result = {
      totalFareAmount: 0,
      totalServiceFee: 0,
    };

    passengersList.forEach((item) => {
      const priceInfo = fee?.itemsList.find((p) => p.type === item.type);

      if (priceInfo) {
        result.totalFareAmount += priceInfo.fareAmount;
        result.totalServiceFee += priceInfo.serviceFee;
      }
    });

    return {
      result,
    };
  }, [fee, passengersList]);
  return (
    <Card
      title='Tổng hợp đơn hàng'
      icon={<MoneyDollarCircleFillIcon className='w-5 h-5 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='p-4 flex mt-1.5 rounded space-x-[40px] text-neutral-10 border'>
      <div className='space-y-2.5 flex-1 '>
        <span className='text-md font-semibold'>Nhập vào</span>
        <div className='flex justify-between'>
          <span>Giá vé (bao gồm thuế & phí)</span>
          <span className='text-md font-bold '>
            {formatPrice(calcPrice.result.totalFareAmount)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span>Dịch vụ bổ sung</span>
          <span className='text-md font-bold '>{formatPrice(0)}</span>
        </div>
        <div className='flex justify-between'>
          <span>Thuế</span>
          <span className='text-md italic'>Đã bao gồm</span>
        </div>
        <div className='flex justify-between pt-2.5 border-t border-neutral-5'>
          <span className='text-lg'>Tổng nhập vào</span>
          <span className='text-lg font-bold text-primary'>
            {formatPrice(calcPrice.result.totalFareAmount)}
          </span>
        </div>
      </div>
      <div className='space-y-2.5 flex-1'>
        <span className='text-md font-semibold'>Bán ra</span>
        <div className='flex justify-between'>
          <span>Tổng nhập vào</span>
          <span className='text-md font-bold '>
            {formatPrice(calcPrice.result.totalFareAmount)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span>Phí dịch vụ</span>
          <span className='text-md font-bold '>
            {formatPrice(calcPrice.result.totalServiceFee)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span>Thuế ({fee.vatConfig}%)</span>
          <span className='text-md font-bold '>{formatPrice(fee.vat)}</span>
        </div>
        <div className='flex justify-between pt-2.5 border-t border-neutral-5'>
          <span className='text-lg'>Tổng bán ra</span>
          <span className='text-lg font-bold text-secondary'>
            {formatPrice(fee.totalPrice)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span>Lợi nhuận</span>
          <span className='text-md font-bold text-green-6'>
            {formatPrice(fee.profit)}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default AggregateOrder;
