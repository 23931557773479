import { FormErrorMessage, TInputSize } from '@common-ui';
import { SwapFlightIcon } from '@icon';

import { FormInputSearchAirport } from '@web-booker/components/Common/Form/FormItem';
import { useSearchAirport } from '@web-booker/hooks/apps';

import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
type Props = {
  startPointName: string;
  endPointName: string;
  inputSize?: TInputSize;
  disabled?: boolean;
  isSwap?: boolean;
};

export function GroupSearchAirport({
  startPointName,
  endPointName,
  inputSize,
  disabled,
  isSwap,
}: Props) {
  const { handleSearch, airportsList, groupFlightByCityName } =
    useSearchAirport();

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { onChange: onChangeStart, value: startPointValue },
    fieldState: { error: errorStartPointName },
  } = useController({
    name: startPointName,
    control,
  });

  const {
    field: { onChange: onChangeEnd, value: endPointValue },
    fieldState: { error: errorEndPointName },
  } = useController({
    name: endPointName,
    control,
  });

  const ShowErrorMessage = () => {
    if (errorStartPointName?.message) {
      return <FormErrorMessage errors={errors} name={startPointName} />;
    } else if (errorEndPointName?.message) {
      return <FormErrorMessage errors={errors} name={endPointName} />;
    }
  };

  function handleSwapAirport() {
    const _startPointValue = startPointValue;
    onChangeStart(endPointValue);
    onChangeEnd(_startPointValue);
  }

  return (
    <div className='space-y-2.5'>
      <div
        className={cn('shrink-0  place-items-baseline space-y-2.5 relative')}>
        <FormInputSearchAirport
          isShowError={false}
          handleSearch={handleSearch}
          groupFlightData={groupFlightByCityName(
            airportsList.filter((item) => item.id !== endPointValue?.id)
          )}
          mode='take-off'
          placeholder='Khởi hành từ'
          name={startPointName}
          inputProps={{
            inputSize,
            disabled,
          }}
          rules={{
            required: 'Chọn nơi đi',
          }}
        />
        <FormInputSearchAirport
          isShowError={false}
          handleSearch={handleSearch}
          groupFlightData={groupFlightByCityName(
            airportsList.filter((item) => item.id !== startPointValue?.id)
          )}
          mode='landing'
          placeholder='Nơi đến'
          name={endPointName}
          inputProps={{
            inputSize,
            disabled,
          }}
          rules={{
            required: 'Chọn nơi đến',
          }}
        />
        {isSwap && (
          <div
            className='absolute right-1.5 bottom-1/2 translate-y-1/2 cursor-pointer'
            onClick={handleSwapAirport}>
            <SwapFlightIcon />
          </div>
        )}
      </div>
      <ShowErrorMessage />
    </div>
  );
}

export default GroupSearchAirport;
