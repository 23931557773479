import { InfoIcon, PlaneIcon } from '@icon';

import { IFlightInfo } from '@tixlabs/grpc-client/web-partner';
import { useTime } from '@web-booker/hooks/internals/useTime';
import { getStopNumTitle } from '@web-booker/utils';
import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { AirlineInfo } from './AirlineInfo';
import { IBookingItinerary } from '@tixlabs/grpc-client';

type Props = {
  data: IFlightInfo | IBookingItinerary;
  onDetail?: () => void;
};

function ColItem({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        'flex flex-col justify-center items-center grow border-r border-r-[#D3D3D3] flex-nowrap',
        className
      )}>
      {children}
    </div>
  );
}

export function BasicFlightInfo({ data, onDetail }: Props) {
  const { formatDateUTCOnlyTime, formatDuration } = useTime();
  const groupFlightNumber = data.flightNumber.split(',');

  return (
    <div className='flex flex-nowrap justify-between w-full '>
      <ColItem className='basis-[140px]'>
        <AirlineInfo code={data.carrierMarketing} />
      </ColItem>
      <ColItem className='basis-[79px]'>
        <div className='text-[13px]'>{data.departPlace}</div>
        <div className='font-bold text-[15px]'>
          {formatDateUTCOnlyTime(data.departDate)}
        </div>
      </ColItem>
      <ColItem className='basis-[79px]'>
        <div className='text-[13px]'>{data.arrivalPlace}</div>
        <div className='font-bold text-[15px]'>
          {formatDateUTCOnlyTime(data.arrivalDate)}
        </div>
      </ColItem>
      <ColItem className='basis-[163px] divide-y divide-[#D3D3D3] text-[13px]'>
        <div className='py-1 text-primary '>
          {data.segmentsList?.[0]?.aircraft}
        </div>
        <div className='divide-x flex py-1'>
          <div className='px-2 text-center whitespace-nowrap'>
            {formatDuration(data.flightDuration)}
          </div>
          <div className='px-2 text-center whitespace-nowrap'>
            {getStopNumTitle(data.segmentsList.length)}
          </div>
        </div>
      </ColItem>
      <ColItem className='basis-[120px] border-r-0 !items-start pl-4 max-w-[120px]'>
        <div className='flex items-center gap-1'>
          <PlaneIcon className='w-5 h-5 text-[#999999]' />
          <span
            title={data.flightNumber}
            className='font-bold text-[15px] leading-6 line-clamp-1'>
            {data.carrierMarketing}
            {groupFlightNumber.length > 1
              ? `${groupFlightNumber[0]}`
              : groupFlightNumber[0]}
          </span>
        </div>
        <div
          onClick={onDetail}
          className='flex items-center gap-1 text-primary cursor-pointer'>
          <InfoIcon className='w-4 h-4' />
          <span className='text-[13px] leading-6'>Chi tiết</span>
        </div>
      </ColItem>
    </div>
  );
}

export default BasicFlightInfo;
