import { Button, ButtonLoading, CloseIcon } from '@common-ui';
import { getApiErrorMessages } from '@core/utils';
import { flightApiService } from '@tixlabs/grpc-client/web-partner';
import { EModalMode } from '@web-admin/types';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import ToastError from '@web-booker/components/Toast/Error';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useAppMutation, useTime, useToast } from '@web-booker/hooks/internals';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  resetBooking,
  updateBookingInfo,
} from '@web-booker/stores/reducers/booking';
import { resetSearchFlight } from '@web-booker/stores/reducers/searchFlight';
import { IModal } from '@web-booker/types';
import { EIssueTicketError, getFlightId } from '@web-booker/utils';
import { Modal } from 'antd';
import { SetStateAction, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmIssueTicket from './ConfirmIssueTicket';
import ConfirmReserveTicket from './ConfirmReserveTicket';

export type TModalPayment = IModal & {
  state: ESwitchModal;
  errorMessage?: string;
  bookingCode?: string;
};

export enum ESwitchModal {
  NONE = 'NONE',
  CONFIRM_ISSUE_TICKET = 'CONFIRM_ISSUE_TICKET',
  CONFIRM_HOLD_TICKET = 'CONFIRM_HOLD_TICKET',
  SUCCESS_ISSUE = 'SUCCESS_ISSUE',
  SUCCESS_HOLD_TICKET = 'SUCCESS_HOLD_TICKET',
  ERROR_BOOKING = 'ERROR_BOOKING',
  INSUFFICIENT_BALANCE_ERROR_ISSUE = 'ERROR_ISSUE',
  OTHER_ERROR_ISSUE = 'OTHER_ERROR_ISSUE',
}

type Props = {
  modalInfo: TModalPayment;
  handleUpdateModalInfo: (value: SetStateAction<TModalPayment>) => void;
};

export const TicketModal = ({ modalInfo, handleUpdateModalInfo }: Props) => {
  const refTimer = useRef<NodeJS.Timeout>();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatDateTime } = useTime();
  const {
    passengersList,
    flightDataList,
    contactInfo,
    calculatedFlightFee,
    sessionId,
    bookingCode,
    expiredAt,
    isInvoiceInfo,
    invoicingInformation,
  } = useBooking();
  const { key } = useAppSelector(
    (state) => state.searchFlight.searchDataHistoryFullData
  );

  const { mutateAsync: bookingFlight, isLoading: isLoadingBooking } =
    useAppMutation({
      mutationKey: ['flightApiService', 'bookingFlight'],
      mutationFn: flightApiService?.bookingFlight,
    });
  const { mutateAsync: issueTicket, isLoading: isLoadingIssue } =
    useAppMutation({
      mutationKey: ['flightApiService', 'issueTicket'],
      mutationFn: flightApiService?.issueTicket,
    });

  const handleBookingTimeOut = () => {
    dispatch(resetBooking());
    handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
    navigate('/booking-flight/search');
  };

  const handleBooking = async (isIssue?: boolean) => {
    try {
      const flightId = getFlightId(flightDataList);

      const { errorCode, isSuccess, data } = await bookingFlight({
        currency: 'VND',
        flightId,
        isPublic: true,
        key: key,
        note: '',
        contactInfo: contactInfo,
        sessionId: sessionId,
        passengersList: passengersList,
        confirmPrice: calculatedFlightFee.totalPrice,
        invoicingInformation: isInvoiceInfo ? invoicingInformation : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (isSuccess && data) {
        dispatch(updateBookingInfo({ ...data }));

        if (isIssue) {
          refTimer.current = setTimeout(
            handleBookingTimeOut,
            data.expiredAt - Date.now()
          );
          await handleIssue(data.bookingCode);
        } else {
          handleUpdateModalInfo((pre) => ({
            ...pre,
            open: true,
            state: ESwitchModal.SUCCESS_HOLD_TICKET,
            bookingCode: data.bookingCode,
            closeIcon: false,
          }));
        }
      }
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      toast.error(errorMessage[0]);
      dispatch(resetBooking());
      handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
      navigate('/booking-flight/search');
    }
  };

  const handleIssue = async (bookingCode: string) => {
    try {
      const { isSuccess, errorCode, data } = await issueTicket({ bookingCode });

      if (isSuccess) {
        clearTimeout(refTimer.current);
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.SUCCESS_ISSUE,
          bookingCode,
          closeIcon: false,
        }));
      }

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      if (EIssueTicketError[errorMessage[0]]) {
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          errorMessage: EIssueTicketError[errorMessage[0]],
          state:
            errorMessage[0] === 'INSUFFICIENT_BALANCE'
              ? ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE
              : ESwitchModal.OTHER_ERROR_ISSUE,
          closeIcon: false,
        }));
      } else {
        toast.error(errorMessage[0]);
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
      }
    }
  };

  const handleOk = async () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_HOLD_TICKET:
        await handleBooking();
        break;
      case ESwitchModal.CONFIRM_ISSUE_TICKET:
        await handleBooking(true);
        break;
      case ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE:
        await handleIssue(bookingCode);
        break;
      case ESwitchModal.OTHER_ERROR_ISSUE:
        dispatch(resetBooking());
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        clearTimeout(refTimer.current);
        navigate('/booking-flight/search');
        break;
      case ESwitchModal.SUCCESS_ISSUE:
        dispatch(resetBooking());
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`./ticket/${modalInfo.bookingCode}`);
        break;
      case ESwitchModal.SUCCESS_HOLD_TICKET:
        dispatch(resetBooking());
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_HOLD_TICKET:
      case ESwitchModal.CONFIRM_ISSUE_TICKET:
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: false,
          state: ESwitchModal.NONE,
        }));
        break;

      case ESwitchModal.SUCCESS_HOLD_TICKET:
      case ESwitchModal.SUCCESS_ISSUE:
        dispatch(resetBooking());
        dispatch(resetSearchFlight());
        navigate(`/booking-flight/search`);
        break;
      default:
        break;
    }
  };

  const generateModalContent = (state: ESwitchModal) => {
    const content = {
      [ESwitchModal.CONFIRM_HOLD_TICKET]: <ConfirmReserveTicket />,
      [ESwitchModal.CONFIRM_ISSUE_TICKET]: <ConfirmIssueTicket />,
      [ESwitchModal.OTHER_ERROR_ISSUE]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Lỗi xuất vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title={modalInfo.errorMessage}
          content={
            <span>
              Số dư ví không đủ, vui lòng yêu cầu tài khoản admin nạp thêm tiền
              vào ví và chọn nút “Xuất vé lại” trước
              <span className='font-bold'> {formatDateTime(expiredAt)}</span>.
              Vui lòng không thoát khỏi trang này, nếu không đơn hàng này sẽ bị
              hủy.
            </span>
          }
        />
      ),
      [ESwitchModal.SUCCESS_ISSUE]: (
        <ModalContent title='Xuất vé thành công' mode={EModalMode.SUCCESS} />
      ),
      [ESwitchModal.SUCCESS_HOLD_TICKET]: (
        <ModalContent
          title='Giữ vé thành công'
          mode={EModalMode.SUCCESS}
          content={
            <ToastError
              className='flex w-full items-center rounded-lg p-5 my-8'
              message={
                <span>
                  Để không bị phạt, đơn giữ chỗ cần được xuất vé hoặc hủy giữ
                  chỗ trước
                  <span className='font-bold'>
                    {' '}
                    {formatDateTime(expiredAt)}
                  </span>
                </span>
              }
            />
          }
        />
      ),
    };
    return content[state];
  };

  const generateModalFooter = (
    state: ESwitchModal,
    onOk: () => void,
    onCancel: () => void
  ) => {
    const content = {
      [ESwitchModal.CONFIRM_HOLD_TICKET]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoadingBooking || isLoadingIssue}>
            Hủy
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoadingBooking || isLoadingIssue}>
            Giữ chỗ
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.CONFIRM_ISSUE_TICKET]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoadingBooking || isLoadingIssue}>
            Hủy
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoadingBooking || isLoadingIssue}>
            Xuất vé
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoadingIssue}>
            Xuất vé lại
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.OTHER_ERROR_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Quay về trang tìm kiếm
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Xem chi tiết đơn hàng
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_HOLD_TICKET]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Chi tiết giữ chỗ
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  return (
    <Modal
      {...modalInfo}
      closeIcon={
        modalInfo.closeIcon ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999] '
            onClick={handleCancel}
          />
        ) : null
      }
      footer={generateModalFooter(modalInfo.state, handleOk, handleCancel)}>
      {generateModalContent(modalInfo.state)}
    </Modal>
  );
};

export default TicketModal;
