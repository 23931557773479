import { Button, Card } from '@common-ui';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useCurrency } from '@web-booker/hooks/internals';
import { DEFAULT_MODAL_CONTENT, PASSENGER_TYPE_LABEL } from '@web-booker/utils';
import { useState } from 'react';
import TicketModal, {
  ESwitchModal,
  TModalPayment,
} from './components/TicketModal';

type Props = {
  showBottomButton?: boolean;
};

const DEFAULT_MODAL_INFO: TModalPayment = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
};

export const PaymentSummaryCtn = ({ showBottomButton }: Props) => {
  const { currency, formatPrice } = useCurrency();

  const [modalInfo, setModalInfo] = useState<TModalPayment>(DEFAULT_MODAL_INFO);

  const {
    calculatedFlightFee: { itemsList, profit, totalPrice },
  } = useBooking();

  const handleClickIssueButton = () => {
    setModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.CONFIRM_ISSUE_TICKET,
      closeIcon: null,
    }));
  };
  const handleClickHold = () => {
    setModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.CONFIRM_HOLD_TICKET,
      closeIcon: null,
    }));
  };

  return (
    <div className='space-y-5 w-[370px]'>
      <Card title={'Tóm tắt hành trình'}>
        <div className='px-2.5 py-4'>
          <div className='flex flex-col space-y-4'>
            <span className='text-md font-semibold'>Thông tin giá vé</span>
            {itemsList.map((item, index) => {
              return (
                <div className='flex justify-between' key={index}>
                  <span>
                    {PASSENGER_TYPE_LABEL[item.type]} x {item.quantity}
                  </span>
                  <div className=' flex space-x-2.5 items-center'>
                    <span className='font-semibold'>
                      {formatPrice(item.totalPrice)}
                    </span>
                    <span>{currency}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='px-2.5 py-4 border-t'>
          <div className='flex flex-col space-y-4'>
            <div className='flex justify-between items-center'>
              <span className='text-md font-semibold'>
                Khách cần thanh toán
              </span>
              <div className=' flex space-x-2.5 items-center'>
                <span className='font-bold text-secondary text-2xl'>
                  {formatPrice(totalPrice)}
                </span>
                <span className='font-normal text-base text-black'>
                  {currency}
                </span>
              </div>
            </div>
            <div className='px-3 py-2 bg-neutral-3 space-y-2'>
              <div className='flex justify-between'>
                <div>Đại lý thanh toán</div>
                <div>
                  <span className='text-primary font-bold text-md'>
                    {formatPrice(totalPrice - profit)}{' '}
                  </span>
                  <span>{currency}</span>
                </div>
              </div>
              <div className='flex justify-between'>
                <div>Tổng lời</div>
                <div>
                  <span className='font-semibold'>{formatPrice(profit)} </span>
                  <span>{currency}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showBottomButton && (
          <div className='p-2.5 space-y-2.5'>
            <div className='flex space-x-2.5 text-common-error'>
              <span>
                Kiểm tra kỹ thông tin chuyến bay & hành khách trước khi xuất vé
                hoặc giữ chỗ.
              </span>
            </div>
            <div className='flex flex-col space-y-2.5'>
              <Button
                size='md'
                theme='green'
                className='text-xl'
                onClick={handleClickIssueButton}>
                Xuất vé
              </Button>
              <Button
                size='md'
                theme='primary'
                className='text-xl'
                onClick={handleClickHold}>
                Giữ chỗ
              </Button>
            </div>
          </div>
        )}
      </Card>

      <TicketModal modalInfo={modalInfo} handleUpdateModalInfo={setModalInfo} />
    </div>
  );
};

export default PaymentSummaryCtn;
