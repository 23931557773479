/* eslint-disable no-case-declarations */
import { EPassengerType } from '@tixlabs/types';
import { ONE_YEAR_TIMESTAMP } from '../constants';

export const validateName = (value: string) => {
  if (value.length > 999) {
    return 'Tối đa 999 kí tự';
  }
  // eslint-disable-next-line no-control-regex
  if (value.match(/[^\x00-\x7F]/)) {
    return 'Nhập chuỗi không chứa dấu';
  }
  if (value.match(/[^a-zA-Z0-9 ]/)) {
    return 'Nhập chuỗi không chứa ký tự đặc biệt';
  }
};

export const validateAgePassenger = (
  dob: Date,
  type: EPassengerType,
  lastFlightStartTime: Date
) => {
  if (dob.getTime() > Date.now()) {
    return 'Ngày sinh không hợp lệ';
  }
  const age = lastFlightStartTime.getTime() - dob.getTime();

  switch (type) {
    case EPassengerType.ADT:
      return (
        !!(age >= 12 * ONE_YEAR_TIMESTAMP) || 'Tuổi người lớn phải lớn hơn 12'
      );
    case EPassengerType.CHD:
      return (
        !!(age < 12 * ONE_YEAR_TIMESTAMP && age >= 2 * ONE_YEAR_TIMESTAMP) ||
        'Độ tuổi của Trẻ em từ 2 - 11 tuổi'
      );
    case EPassengerType.INF:
      return (
        !!(age < 2 * ONE_YEAR_TIMESTAMP) ||
        'Độ tuổi Trẻ sơ sinh từ 0 đến 1 tuổi'
      );
    default:
      return true;
  }
};

export const validatePassportNumber = (value: string) => {
  const patternPassPort = /^[a-zA-Z0-9]{5,15}$/;
  if (!value) {
    return true;
  }
  return !!value.match(patternPassPort) || 'Số passport không hợp lệ';
};

export const validatePhoneNumber = (value: string) => {
  const patternNumberString = /^\d+$/;
  return !!value.match(patternNumberString) || 'Số điện thoại không hợp lệ';
};

export const validateEmail = (value: string) => {
  const patternEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !!value.match(patternEmail) || 'Email không hợp lệ';
};
