import useBooking from '@web-booker/hooks/apps/useBooking';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmContactInfoCtn } from './ConfirmContactInfo';

import { Card, GroupFillIcon } from '@common-ui';
import { InvoiceInfoView } from '@web-booker/components/OrderManagement';
import { ConfirmPassengerInfoCtn } from './ConfirmPassengerInfo';

type Props = {
  //
};

export const ConfirmBookingInfoCtn = (props: Props) => {
  const navigate = useNavigate();
  const { flightDataList, isInvoiceInfo, invoicingInformation } = useBooking();

  useEffect(() => {
    if (!flightDataList.length) {
      navigate('../search');
    }
  }, []);
  return (
    <div className='flex flex-col space-y-5'>
      <ConfirmPassengerInfoCtn />
      <ConfirmContactInfoCtn />
      {isInvoiceInfo && (
        <Card
          title='Thông tin xuất hóa đơn'
          icon={<GroupFillIcon />}
          classNameChildren='px-3 py-5'
          isCollapse>
          <InvoiceInfoView data={invoicingInformation} />
        </Card>
      )}
    </div>
  );
};

export default ConfirmBookingInfoCtn;
