import { IPassengerQuantity } from '@tixlabs/grpc-client/web-partner';

import { Button } from '@common-ui';
import { useEffect, useMemo, useState } from 'react';
import { FlightClassesListInfo } from '../../../components/Flights/FlightClassesListInfo';

import { IFlightInfo } from '@tixlabs/grpc-client/web-partner';
import FlightItem from '@web-booker/components/Flights/FlightItem';
import { useSelectFlight } from '@web-booker/hooks/apps';
import { useAppSelector } from '@web-booker/stores';
import { EFilterDisplayMode } from '@web-booker/types/ui/flight';

type Props = {
  data: IFlightInfo;
  passengerQuantity: IPassengerQuantity;
};

export function SearchFlightItem({ data, passengerQuantity }: Props) {
  const { handleSelectFlight } = useSelectFlight();

  const {
    bookingInfo: { flightDataList },
  } = useAppSelector((state) => state.booking);

  const {
    filter: { flightsList },
    currentSelectIndex,
    filterClient: { displayMode },
  } = useAppSelector((state) => state.searchFlight);

  const [activeIndex, setActiveIndex] = useState(0);

  const sortClassList = useMemo(
    () =>
      [...data.classesList].sort((a, b) => {
        const priceDataA = a.validFlightClassData?.totalFareBasic || 0;
        const priceDataB = b.validFlightClassData?.totalFareBasic || 0;

        return priceDataA - priceDataB;
      }) || [],
    [data.classesList]
  ).map((data) => {
    const priceData = data.validFlightClassData;

    let priceToShowByMode = 0;
    switch (displayMode) {
      case EFilterDisplayMode.TOTAL_PASSENGER:
        priceToShowByMode = priceData?.displayPrice || 0;
        break;
      case EFilterDisplayMode.BASIC_ADT:
        priceToShowByMode = priceData?.totalPaxFareAdt?.fareBasic || 0;
        break;
      case EFilterDisplayMode.TOTAL_PER_ADT:
        priceToShowByMode = priceData?.totalPaxFareAdt?.displayPrice || 0;
        break;
      default:
        priceToShowByMode = priceData?.displayPrice || 0;
        break;
    }
    return { ...data, displayPrice: priceToShowByMode };
  });

  function onSelectFlight() {
    handleSelectFlight(data, sortClassList[activeIndex]);
  }

  function findCurrentActiveClass(prevSelectedFlight: IFlightInfo) {
    const currentClass = prevSelectedFlight?.classesList?.[0];

    if (currentClass) {
      const findActiveIndex = data.classesList.findIndex(
        (item) =>
          currentClass.validFlightClassData?.flightId ===
          item.validFlightClassData?.flightId
      );
      if (findActiveIndex >= 0) {
        setActiveIndex(findActiveIndex);
      }
    }
  }

  useEffect(() => {
    if (flightDataList[currentSelectIndex - 1]) {
      findCurrentActiveClass(flightDataList[currentSelectIndex - 1]);
    }
  }, []);

  return (
    <div className=''>
      <FlightItem
        flightIndex={currentSelectIndex}
        className='bg-neutral-1 px-2.5'
        classNamePanel='mb-2.5'
        data={data}
        passengerQuantity={passengerQuantity}
        activeClassIndex={activeIndex}>
        <div className='flex py-2.5 flex-nowrap items-stretch content-center space-x-1'>
          <FlightClassesListInfo
            activeIndex={activeIndex}
            className='shrink-0'
            data={sortClassList}
            changeActiveIndex={(value) => setActiveIndex(value)}
            flightQuantity={flightsList.length}
          />
          {/* <div className='text-neutral-10 text-center flex flex-col items-center justify-center bg-neutral-4 rounded-[3px] px-2 py-1'>
              <span className='font-bold text-[15px]'>
                {data.classesList[activeIndex].seatRemain}
              </span>
              <span className='text-[13px]'>chỗ</span>
            </div> */}

          <Button
            onClick={onSelectFlight}
            className='w-[90px] !rounded'
            variant='solid'
            theme='green'
            rounded='md'>
            <div className='flex items-center gap-1'>
              Chọn
              {/* <ArrowDownSLineIcon className='-rotate-90' /> */}
            </div>
          </Button>
        </div>
      </FlightItem>
    </div>
  );
}

export default SearchFlightItem;
