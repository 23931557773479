// source: airplane/hub.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var airplane_base_pb = require('../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
goog.exportSymbol('proto.airplane.Airline', null, global);
goog.exportSymbol('proto.airplane.HubBaggage', null, global);
goog.exportSymbol('proto.airplane.HubFlightInfo', null, global);
goog.exportSymbol('proto.airplane.HubItinerary', null, global);
goog.exportSymbol('proto.airplane.HubRouting', null, global);
goog.exportSymbol('proto.airplane.HubSegment', null, global);
goog.exportSymbol('proto.airplane.PaymentInfo', null, global);
goog.exportSymbol('proto.airplane.PaymentInfo.PaymentMethod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubRouting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubRouting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubRouting.displayName = 'proto.airplane.HubRouting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubFlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubFlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubFlightInfo.displayName = 'proto.airplane.HubFlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubSegment.displayName = 'proto.airplane.HubSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubBaggage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubBaggage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubBaggage.displayName = 'proto.airplane.HubBaggage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubItinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.HubItinerary.repeatedFields_, null);
};
goog.inherits(proto.airplane.HubItinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubItinerary.displayName = 'proto.airplane.HubItinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.Airline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.Airline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.Airline.displayName = 'proto.airplane.Airline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.PaymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.PaymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.PaymentInfo.displayName = 'proto.airplane.PaymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.PaymentInfo.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.PaymentInfo.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.PaymentInfo.PaymentMethod.displayName = 'proto.airplane.PaymentInfo.PaymentMethod';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubRouting.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubRouting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubRouting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubRouting.toObject = function(includeInstance, msg) {
  var f, obj = {
    departPlace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubRouting}
 */
proto.airplane.HubRouting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubRouting;
  return proto.airplane.HubRouting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubRouting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubRouting}
 */
proto.airplane.HubRouting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubRouting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubRouting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubRouting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubRouting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string depart_place = 1;
 * @return {string}
 */
proto.airplane.HubRouting.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 depart_date = 2;
 * @return {number}
 */
proto.airplane.HubRouting.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string arrival_place = 3;
 * @return {string}
 */
proto.airplane.HubRouting.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 arrival_date = 4;
 * @return {number}
 */
proto.airplane.HubRouting.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubFlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubFlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubFlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubFlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 1, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    aircraft: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubFlightInfo}
 */
proto.airplane.HubFlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubFlightInfo;
  return proto.airplane.HubFlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubFlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubFlightInfo}
 */
proto.airplane.HubFlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAircraft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubFlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubFlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubFlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubFlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAircraft();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string carrier_marketing = 1;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string carrier_operator = 2;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_number = 3;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string aircraft = 4;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getAircraft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setAircraft = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    routing: (f = msg.getRouting()) && proto.airplane.HubRouting.toObject(includeInstance, f),
    flightInfo: (f = msg.getFlightInfo()) && proto.airplane.HubFlightInfo.toObject(includeInstance, f),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubSegment}
 */
proto.airplane.HubSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubSegment;
  return proto.airplane.HubSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubSegment}
 */
proto.airplane.HubSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = new proto.airplane.HubRouting;
      reader.readMessage(value,proto.airplane.HubRouting.deserializeBinaryFromReader);
      msg.setRouting(value);
      break;
    case 3:
      var value = new proto.airplane.HubFlightInfo;
      reader.readMessage(value,proto.airplane.HubFlightInfo.deserializeBinaryFromReader);
      msg.setFlightInfo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRouting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.HubRouting.serializeBinaryToWriter
    );
  }
  f = message.getFlightInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.HubFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.airplane.HubSegment.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional HubRouting routing = 2;
 * @return {?proto.airplane.HubRouting}
 */
proto.airplane.HubSegment.prototype.getRouting = function() {
  return /** @type{?proto.airplane.HubRouting} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubRouting, 2));
};


/**
 * @param {?proto.airplane.HubRouting|undefined} value
 * @return {!proto.airplane.HubSegment} returns this
*/
proto.airplane.HubSegment.prototype.setRouting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.clearRouting = function() {
  return this.setRouting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubSegment.prototype.hasRouting = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HubFlightInfo flight_info = 3;
 * @return {?proto.airplane.HubFlightInfo}
 */
proto.airplane.HubSegment.prototype.getFlightInfo = function() {
  return /** @type{?proto.airplane.HubFlightInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubFlightInfo, 3));
};


/**
 * @param {?proto.airplane.HubFlightInfo|undefined} value
 * @return {!proto.airplane.HubSegment} returns this
*/
proto.airplane.HubSegment.prototype.setFlightInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.clearFlightInfo = function() {
  return this.setFlightInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubSegment.prototype.hasFlightInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 flight_duration = 4;
 * @return {number}
 */
proto.airplane.HubSegment.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubBaggage.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubBaggage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubBaggage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubBaggage.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isHandBaggage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paxType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.HubBaggage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubBaggage;
  return proto.airplane.HubBaggage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubBaggage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.HubBaggage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHandBaggage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setPaxType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubBaggage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubBaggage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubBaggage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubBaggage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsHandBaggage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.HubBaggage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.airplane.HubBaggage.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_hand_baggage = 3;
 * @return {boolean}
 */
proto.airplane.HubBaggage.prototype.getIsHandBaggage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setIsHandBaggage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 quantity = 4;
 * @return {number}
 */
proto.airplane.HubBaggage.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PaxType pax_type = 5;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.HubBaggage.prototype.getPaxType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setPaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.HubItinerary.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubItinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubItinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubItinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubItinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 4, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 5, 0),
    routing: (f = msg.getRouting()) && proto.airplane.HubRouting.toObject(includeInstance, f),
    flightInfo: (f = msg.getFlightInfo()) && proto.airplane.HubFlightInfo.toObject(includeInstance, f),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 10, ""),
    freeBaggageList: jspb.Message.toObjectList(msg.getFreeBaggageList(),
    proto.airplane.HubBaggage.toObject, includeInstance),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.airplane.HubSegment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.HubItinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubItinerary;
  return proto.airplane.HubItinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubItinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.HubItinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 6:
      var value = new proto.airplane.HubRouting;
      reader.readMessage(value,proto.airplane.HubRouting.deserializeBinaryFromReader);
      msg.setRouting(value);
      break;
    case 7:
      var value = new proto.airplane.HubFlightInfo;
      reader.readMessage(value,proto.airplane.HubFlightInfo.deserializeBinaryFromReader);
      msg.setFlightInfo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFlightDuration(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 11:
      var value = new proto.airplane.HubBaggage;
      reader.readMessage(value,proto.airplane.HubBaggage.deserializeBinaryFromReader);
      msg.addFreeBaggage(value);
      break;
    case 12:
      var value = new proto.airplane.HubSegment;
      reader.readMessage(value,proto.airplane.HubSegment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubItinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubItinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubItinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubItinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRouting();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.airplane.HubRouting.serializeBinaryToWriter
    );
  }
  f = message.getFlightInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.HubFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFreeBaggageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.airplane.HubBaggage.serializeBinaryToWriter
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.airplane.HubSegment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stop_number = 3;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string fare_basis = 4;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 availability = 5;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional HubRouting routing = 6;
 * @return {?proto.airplane.HubRouting}
 */
proto.airplane.HubItinerary.prototype.getRouting = function() {
  return /** @type{?proto.airplane.HubRouting} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubRouting, 6));
};


/**
 * @param {?proto.airplane.HubRouting|undefined} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setRouting = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearRouting = function() {
  return this.setRouting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubItinerary.prototype.hasRouting = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HubFlightInfo flight_info = 7;
 * @return {?proto.airplane.HubFlightInfo}
 */
proto.airplane.HubItinerary.prototype.getFlightInfo = function() {
  return /** @type{?proto.airplane.HubFlightInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubFlightInfo, 7));
};


/**
 * @param {?proto.airplane.HubFlightInfo|undefined} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setFlightInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearFlightInfo = function() {
  return this.setFlightInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubItinerary.prototype.hasFlightInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 flight_duration = 8;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string cabin_class = 9;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string booking_class = 10;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated HubBaggage free_baggage = 11;
 * @return {!Array<!proto.airplane.HubBaggage>}
 */
proto.airplane.HubItinerary.prototype.getFreeBaggageList = function() {
  return /** @type{!Array<!proto.airplane.HubBaggage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.HubBaggage, 11));
};


/**
 * @param {!Array<!proto.airplane.HubBaggage>} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setFreeBaggageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.HubBaggage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.HubItinerary.prototype.addFreeBaggage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.HubBaggage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearFreeBaggageList = function() {
  return this.setFreeBaggageList([]);
};


/**
 * repeated HubSegment segments = 12;
 * @return {!Array<!proto.airplane.HubSegment>}
 */
proto.airplane.HubItinerary.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.HubSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.HubSegment, 12));
};


/**
 * @param {!Array<!proto.airplane.HubSegment>} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.airplane.HubSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubSegment}
 */
proto.airplane.HubItinerary.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.airplane.HubSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.Airline.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.Airline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.Airline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Airline.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.Airline}
 */
proto.airplane.Airline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.Airline;
  return proto.airplane.Airline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.Airline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.Airline}
 */
proto.airplane.Airline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.Airline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.Airline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.Airline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Airline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.Airline.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Airline} returns this
 */
proto.airplane.Airline.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.airplane.Airline.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Airline} returns this
 */
proto.airplane.Airline.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.PaymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.PaymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.PaymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    method: (f = msg.getMethod()) && proto.airplane.PaymentInfo.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.PaymentInfo}
 */
proto.airplane.PaymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.PaymentInfo;
  return proto.airplane.PaymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.PaymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.PaymentInfo}
 */
proto.airplane.PaymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 3:
      var value = new proto.airplane.PaymentInfo.PaymentMethod;
      reader.readMessage(value,proto.airplane.PaymentInfo.PaymentMethod.deserializeBinaryFromReader);
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.PaymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.PaymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.PaymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMethod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.PaymentInfo.PaymentMethod.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.PaymentInfo.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.PaymentInfo.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.PaymentInfo.PaymentMethod}
 */
proto.airplane.PaymentInfo.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.PaymentInfo.PaymentMethod;
  return proto.airplane.PaymentInfo.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.PaymentInfo.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.PaymentInfo.PaymentMethod}
 */
proto.airplane.PaymentInfo.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.PaymentInfo.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.PaymentInfo.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.PaymentInfo.PaymentMethod} returns this
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.PaymentInfo.PaymentMethod} returns this
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double fee_amount = 1;
 * @return {number}
 */
proto.airplane.PaymentInfo.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaymentInfo} returns this
 */
proto.airplane.PaymentInfo.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double total_price = 2;
 * @return {number}
 */
proto.airplane.PaymentInfo.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaymentInfo} returns this
 */
proto.airplane.PaymentInfo.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional PaymentMethod method = 3;
 * @return {?proto.airplane.PaymentInfo.PaymentMethod}
 */
proto.airplane.PaymentInfo.prototype.getMethod = function() {
  return /** @type{?proto.airplane.PaymentInfo.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.airplane.PaymentInfo.PaymentMethod, 3));
};


/**
 * @param {?proto.airplane.PaymentInfo.PaymentMethod|undefined} value
 * @return {!proto.airplane.PaymentInfo} returns this
*/
proto.airplane.PaymentInfo.prototype.setMethod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.PaymentInfo} returns this
 */
proto.airplane.PaymentInfo.prototype.clearMethod = function() {
  return this.setMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.PaymentInfo.prototype.hasMethod = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.airplane);
