import {
  IPassengerQuantity,
  ISearchFlightFilter,
} from '@tixlabs/grpc-client/web-partner';

import { EItineraryType } from '@web-booker/types/ui';
import {
  EFilterDisplayMode,
  EStopPointType,
  IFormFilterFlight,
  IFormSearchFlight,
} from '@web-booker/types/ui/flight';
import { convertTimeRangeToValue } from '../helpers';

export const itineraryTypeLabel = {
  [EItineraryType.ONE_WAY]: 'Một chiều',
  [EItineraryType.ROUND_TRIP]: 'Khứ hồi',
  [EItineraryType.MULTIPLE_TRIP]: 'Đa chặng',
};

export const itineraryTypeOptionList = [
  EItineraryType.ONE_WAY,
  EItineraryType.ROUND_TRIP,
  EItineraryType.MULTIPLE_TRIP,
];

export const itineraryTypeOptions = itineraryTypeOptionList.map((item) => ({
  label: itineraryTypeLabel[item],
  value: item,
}));

export const defaultSearchFlight: IFormSearchFlight = {
  itineraryType: EItineraryType.ONE_WAY,
  passengerQuantity: {
    adt: 1,
    chd: 0,
    inf: 0,
  },
  flightList: [
    {
      departDate: null,
      endPoint: null,
      startPoint: null,
    },
    {
      departDate: null,
      endPoint: null,
      startPoint: null,
    },
    // {
    //   departDate: new Date('2023-09-29T17:00:00.000Z'),
    //   endPoint: {
    //     id: '64b4c81a11275135a4278701',
    //     country: 'Việt Nam',
    //     countryCode: 'VN',
    //     city: 'Hà Nội',
    //     cityCode: 'HAN',
    //     code: 'HAN',
    //     name: 'Sân bay Nội Bài',
    //     lat: 105.80699920654297,
    //     lon: 21.221200942993164,
    //   },
    //   startPoint: {
    //     id: '64b4c81a11275135a4278b10',
    //     country: 'Việt Nam',
    //     countryCode: 'VN',
    //     city: 'Hồ Chí Minh',
    //     cityCode: 'SGN',
    //     code: 'SGN',
    //     name: 'Sân bay Tân Sơn Nhất ',
    //     lat: 106.6520004272461,
    //     lon: 10.81879997253418,
    //   },
    // },
    // {
    //   departDate: new Date('2023-11-03T17:00:00.000Z'),
    //   endPoint: {
    //     id: '64b4c81b11275135a4278c2e',
    //     country: 'Việt Nam',
    //     countryCode: 'VN',
    //     city: 'Côn Đảo',
    //     cityCode: 'VCS',
    //     code: 'VCS',
    //     name: 'Sân bay Cỏ Ống',
    //     lat: 106.63300323486328,
    //     lon: 8.731829643249512,
    //   },
    //   startPoint: {
    //     id: '64b4c81a11275135a4278701',
    //     country: 'Việt Nam',
    //     countryCode: 'VN',
    //     city: 'Hà Nội',
    //     cityCode: 'HAN',
    //     code: 'HAN',
    //     name: 'Sân bay Nội Bài',
    //     lat: 105.80699920654297,
    //     lon: 21.221200942993164,
    //   },
    // },
    // {
    //   departDate: new Date('2023-12-29T17:00:00.000Z'),
    //   endPoint: {
    //     id: '64b4c81a11275135a42785ce',
    //     country: 'Việt Nam',
    //     countryCode: 'VN',
    //     city: 'Đà Nẵng',
    //     cityCode: 'DAD',
    //     code: 'DAD',
    //     name: 'Sân bay Đà Nẵng',
    //     lat: 108.1989974975586,
    //     lon: 16,
    //   },
    //   startPoint: {
    //     id: '64b4c81a11275135a4278b10',
    //     country: 'Việt Nam',
    //     countryCode: 'VN',
    //     city: 'Hồ Chí Minh',
    //     cityCode: 'SGN',
    //     code: 'SGN',
    //     name: 'Sân bay Tân Sơn Nhất ',
    //     lat: 106.6520004272461,
    //     lon: 10.81879997253418,
    //   },
    // },
  ],
  searchDate: {
    startDate: new Date(),
    endDate: undefined,
  },
  onlyDirectFlight: false,
  oneWayAndRoundField: {
    departDate: null,
    endPoint: null,
    startPoint: null,
  },
};

export const defaultFilterFlight: IFormFilterFlight = {
  displayMode: EFilterDisplayMode.TOTAL_PASSENGER,
  airlines: [],
  stopPointType: [
    {
      label: 'Bay thẳng',
      value: EStopPointType.STOP_POINT_TYPE_NONE_STOP,
      isSelected: false,
    },
    {
      label: '1 điểm dừng',
      value: EStopPointType.STOP_POINT_TYPE_ONE,
      isSelected: false,
    },
    {
      label: 'Nhiều điểm dừng',
      value: EStopPointType.STOP_POINT_TYPE_OVER_TWO,
      isSelected: false,
    },
  ],
  ticketClass: [
    // {
    //   label: 'Economy',
    //   value: 'Economy',
    //   isSelected: false,
    // },
    // {
    //   label: 'Business',
    //   value: 'Business',
    //   isSelected: false,
    // },
  ],
  landingTimes: [
    {
      label: 'Đêm đến sáng',
      durationLabel: '00:00 - 06:00',
      value: convertTimeRangeToValue(0, 6),
      isSelected: false,
    },
    {
      label: 'Sáng đến trưa',
      durationLabel: '06:00 - 12:00',
      value: convertTimeRangeToValue(6, 12),
      isSelected: false,
    },
    {
      label: 'Trưa đến tối',
      durationLabel: '12:00 - 18:00',
      value: convertTimeRangeToValue(12, 18),
      isSelected: false,
    },
    {
      label: 'Tối đến đêm',
      durationLabel: '18:00 - 24:00',
      value: convertTimeRangeToValue(18, 24),
      isSelected: false,
    },
  ],
  takeOffRanges: [
    {
      label: 'Đêm đến sáng',
      durationLabel: '00:00 - 06:00',
      value: convertTimeRangeToValue(0, 6),
      isSelected: false,
    },
    {
      label: 'Sáng đến trưa',
      durationLabel: '06:00 - 12:00',
      value: convertTimeRangeToValue(6, 12),
      isSelected: false,
    },
    {
      label: 'Trưa đến tối',
      durationLabel: '12:00 - 18:00',
      value: convertTimeRangeToValue(12, 18),
      isSelected: false,
    },
    {
      label: 'Tối đến đêm',
      durationLabel: '18:00 - 24:00',
      value: convertTimeRangeToValue(18, 24),
      isSelected: false,
    },
  ],
};

export const DEFAULT_PASSENGERS_QTY: IPassengerQuantity = {
  adt: 1,
  chd: 0,
  inf: 0,
};

export const DEFAULT_FILTER: ISearchFlightFilter = {
  flightsList: [],
  passengerQuantity: DEFAULT_PASSENGERS_QTY,
};
