import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import sample from './sample';
// import samplePersist from './sample-persist';
import additionalBaggage from './additional-baggage';
import booking from './booking';
import common from './common';
import layout from './layout';
import searchFlight from './searchFlight';
import searchFlightHistory from './searchFlightHistory';
import user from './user';

export const rootReducer = combineReducers({
  // sample: sample,
  // samplePersist: persistReducer(
  //   {
  //     key: 'sample-persist',
  //     version: 1,
  //     storage,
  //   },
  //   samplePersist
  // ),
  common: persistReducer(
    {
      key: 'common-persist',
      version: 1.2,
      storage,
    },
    common
  ),
  user,
  baggage: additionalBaggage,
  searchFlight,
  booking,
  // searchFlight: persistReducer(
  //   {
  //     key: 'searchFlight-persist',
  //     version: 1.2,
  //     storage,
  //   },
  //   searchFlight
  // ),
  // booking: persistReducer(
  //   {
  //     key: 'booking-persist',
  //     version: 1.2,
  //     storage,
  //   },
  //   booking
  // ),
  layout,
  searchFlightHistory,
});

export default rootReducer;
