import { useAirlines } from '@web-booker/hooks/stores';
import cn from 'classnames';

type Props = {
  className?: string;
  showName?: boolean;
  code: string;
  classNameImg?: string;
};

export const AirlineInfo = ({
  code,
  className,
  showName = true,
  classNameImg,
}: Props) => {
  const { getAirlineNameByCode, getAirlineByCode } = useAirlines();

  const airlineData = getAirlineByCode(code);

  return (
    <div
      className={cn('flex flex-col items-center', {
        [className || '']: !!className,
      })}>
      <img
        draggable={false}
        className={cn(
          'h-5 max-w-full object-contain object-center mb-0.5',
          classNameImg
        )}
        src={airlineData?.logoUrl}
        alt={airlineData?.code}
      />

      {showName && (
        <span className='text-xs whitespace-nowrap'>
          <b>{code}</b> - {getAirlineNameByCode(code)}
        </span>
      )}
    </div>
  );
};
