import { Button, ChooseAgainIcon } from '@common-ui';
import {
  IFlightInfo,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';

import FlightItem from '@web-booker/components/Flights/FlightItem';
import { useSelectFlight } from '@web-booker/hooks/apps';
import { useAirlines } from '@web-booker/hooks/stores';
import { useLocation } from 'react-router-dom';

type TSelectedFlightItemProps = {
  data: IFlightInfo;
  passengerQuantity: IPassengerQuantity;
  index: number;
};

const SelectedFlightItem = ({
  data,
  passengerQuantity,
  index,
}: TSelectedFlightItemProps) => {
  const { pathname } = useLocation();
  const { getAirlineNameByCode } = useAirlines();

  const { handleChangeFlight, handleCancelChangeFlight, currentSelectIndex } =
    useSelectFlight();

  function onChangeFlight() {
    handleChangeFlight(index);
  }

  const isSearchTab = pathname === '/booking-flight/search';
  const isConfirmTab = pathname === '/booking-flight/confirm';
  return (
    <FlightItem
      flightIndex={index}
      data={data}
      passengerQuantity={passengerQuantity}
      className='border rounded'>
      <div className='flex justify-between border-l border-l-[#D3D3D3] items-center w-[400px] pl-5 pr-2.5'>
        <div className='space-y-1 whitespace-nowrap w-full'>
          <div>Hãng vận chuyển</div>
          <div>
            <span className='font-semibold'>{data.carrierOperator}</span>-
            <span>{getAirlineNameByCode(data.carrierOperator)}</span>
          </div>
        </div>

        {!isConfirmTab &&
          (currentSelectIndex === index && isSearchTab ? (
            <Button
              className='whitespace-nowrap'
              onClick={handleCancelChangeFlight}
              variant='outline'
              theme='black'
              prefixIcon={<ChooseAgainIcon className='w-5 h-5' />}>
              Hủy chọn lại
            </Button>
          ) : (
            <Button
              className='whitespace-nowrap'
              onClick={onChangeFlight}
              variant='outline'
              prefixIcon={<ChooseAgainIcon className='w-5 h-5' />}>
              Chọn lại
            </Button>
          ))}
      </div>
    </FlightItem>
  );
};

export default SelectedFlightItem;
