import { PaxType } from '@api/airplane/base_pb';
import { InvoicingInformation } from '@api/airplane/flight_pb';
import { PassengerQuantity } from '@api/airplane/web/flight_pb';
import { BookingFlightReq } from '@api/airplane/web_partner/flight_pb';
import { GENDER, IdType } from '@api/base/base_pb';
import { Gender as GenderCustomer } from '@api/customer/customer_pb';

export interface IPassengerQuantity extends PassengerQuantity.AsObject {}

//! NEED REFACTOR AFTER INTEGRATE HUB BAGGAGE
export interface IBaggage {
  airline: string;
  value: string;
  code: string;
  name: string;
  price: number;
  currency: string;
  startPoint: string;
  endPoint: string;
  route: string;
  leg: number;
  statusCode: string;
  confirmed: boolean;
  id: string;
}

export type EGender = GENDER;
export const EGender = { ...GENDER } as const;
export type EGenderCustomer = GenderCustomer;
export const EGenderCustomer = { ...GenderCustomer } as const;

export type EIdType = IdType;
export const EIdType = { ...IdType } as const;

export type EPassengerType = PaxType;
export const EPassengerType = {
  ...PaxType,
} as const;

export interface IBookingFlightContactInfo
  extends BookingFlightReq.ContactInfo.AsObject {}

export interface IBookingFlightPassenger
  extends BookingFlightReq.Passenger.AsObject {}
