import { IFlightClass, IFlightInfo } from '@tixlabs/grpc-client/web-partner';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { addBookingFlight } from '@web-booker/stores/reducers/booking';
import {
  clearSearchHistoryAfterIndex,
  updateSelectIndex,
} from '@web-booker/stores/reducers/searchFlight';
import { EItineraryType } from '@web-booker/types';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  //
};

export function useSelectFlight() {
  const navigate = useNavigate();

  const {
    currentSelectIndex,
    searchDataHistory,
    filter: { flightsList },
    itineraryType,
  } = useAppSelector((state) => state.searchFlight);

  const {
    bookingInfo: { flightDataList },
  } = useAppSelector((state) => state.booking);

  const dispatch = useAppDispatch();
  function handleSelectFlight(data: IFlightInfo, selectClass: IFlightClass) {
    const hasChooseFlight = flightDataList?.[currentSelectIndex];

    if (hasChooseFlight) {
      dispatch(clearSearchHistoryAfterIndex(currentSelectIndex));
    }

    // console.log('handleSelectFlight', data, selectClass);
    dispatch(
      addBookingFlight({
        index: currentSelectIndex,
        sessionId: searchDataHistory[currentSelectIndex].key,
        flightDetail: {
          ...data,
          classesList: [selectClass],
        },
      })
    );

    if (
      itineraryType === EItineraryType.ONE_WAY ||
      flightsList.length - 1 === currentSelectIndex
    ) {
      navigate('/booking-flight/information');
      return;
    }

    dispatch(updateSelectIndex(currentSelectIndex + 1));
  }

  const handleDebounceSelectFlight = useCallback(
    debounce(handleSelectFlight, 300),
    [currentSelectIndex, searchDataHistory, itineraryType]
  );

  function handleCancelChangeFlight() {
    dispatch(updateSelectIndex(flightsList.length - 1));
    if (flightsList.length === flightDataList.length) {
      navigate('./information');
    }
  }

  function handleChangeFlight(index: number) {
    dispatch(updateSelectIndex(index));
    navigate('./search');
  }

  return {
    handleSelectFlight: handleDebounceSelectFlight,
    handleChangeFlight,
    handleCancelChangeFlight,
    currentSelectIndex,
  };
}

export default useSelectFlight;
