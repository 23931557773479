import {
  Card,
  FormCountry,
  FormInput,
  FormInputDate,
  FormSelect,
} from '@common-ui';
import { EPassengerType } from '@tixlabs/types';

import { useCountry } from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useTime } from '@web-booker/hooks/internals';
import {
  ONE_DAY_TIMESTAMP,
  validateAgePassenger,
  validateName,
  validatePassportNumber,
} from '@web-booker/utils';
import {
  GENDER_OPTIONS,
  PASSENGER_TYPE_LABEL,
} from '@web-booker/utils/constants/booking';
import { useFormContext } from 'react-hook-form';

type Props = {
  indexPassenger: number;
  passengerType: EPassengerType;
  fieldName: string;
};

export const GetInfoByTypePassenger = ({
  indexPassenger,
  passengerType,
  fieldName,
}: Props) => {
  const { countryNameOptionsValueCode } = useCountry();
  const { flightDataList, isOnlyDomesticBooking } = useBooking();
  const { formState } = useFormContext();
  const { formatDate } = useTime();

  const lastDepartDate =
    flightDataList?.[flightDataList.length - 1]?.departDate || Date.now();

  return (
    <Card
      title={PASSENGER_TYPE_LABEL[passengerType]}
      icon={
        <div className='w-5 h-5 text-center bg-primary rounded-full text-white text-sm'>
          {indexPassenger + 1}
        </div>
      }
      className='!bg-[#F9F9F9]'
      classNameTitle='text-sm'
      isCollapse
      defaultShow
      isForceOpen={formState.isSubmitted && !formState.isValid}
      classNameChildren='p-2.5'>
      <div className='flex flex-col space-y-4 '>
        <div
          style={{
            gridTemplateColumns: '150px 180px 1fr 1fr 1fr',
          }}
          className='grid grid-flow-row-dense gap-2.5'>
          <div className='basis-12'>
            <FormSelect
              name={`${fieldName}.gender`}
              label='Giới tính'
              placeholder='Chọn giới tính'
              selectOptions={GENDER_OPTIONS}
              inputProps={{
                isOnlyValue: true,
              }}
              labelProps={{
                isRequired: true,
              }}
              rules={{
                validate: {
                  exist: (v) => {
                    return !v ? `Chọn giới tính` : undefined;
                  },
                },
              }}
            />
          </div>
          <div className=''>
            <FormInput
              name={`${fieldName}.lastName`}
              label='Họ'
              placeholder='Nhập họ'
              inputProps={{
                forceUpperCase: true,
              }}
              labelProps={{
                isRequired: true,
              }}
              rules={{
                required: 'Chưa nhập họ',
                validate: {
                  name: (v) => validateName(v),
                },
              }}
            />
          </div>
          <div className=''>
            <FormInput
              name={`${fieldName}.firstName`}
              label='Tên đệm và tên'
              placeholder='Nhập tên đệm và tên'
              labelProps={{
                isRequired: true,
              }}
              inputProps={{
                forceUpperCase: true,
              }}
              rules={{
                required: 'Chưa nhập tên đệm và tên',
                validate: {
                  name: (v) => validateName(v),
                },
              }}
            />
          </div>
          <div className=''>
            <FormInputDate
              name={`${fieldName}.dob`}
              label='Ngày sinh'
              placeholder='dd/mm/yyyy'
              inputProps={{
                showMonthDropdown: true,
                showYearDropdown: true,
                dropdownMode: 'select',
                maxDate: new Date(),
              }}
              labelProps={{
                isRequired: true,
              }}
              rules={{
                required: 'Nhập ngày sinh',
                validate: {
                  age: (value) => {
                    return validateAgePassenger(
                      value,
                      passengerType,
                      new Date(
                        flightDataList?.[flightDataList.length - 1]
                          ?.departDate || Date.now()
                      )
                    );
                  },
                },
              }}
            />
          </div>
          <div>
            <FormCountry
              name={`${fieldName}.nationality`}
              label='Quốc tịch'
              placeholder='Chọn'
              labelProps={{ isRequired: true }}
              inputProps={{
                isOnlyValue: true,
              }}
              rules={{
                required: 'Chưa chọn quốc tịch',
              }}
            />
          </div>
          {!isOnlyDomesticBooking && (
            <>
              <div className='col-span-2'>
                <FormInput
                  name={`${fieldName}.passportNumber`}
                  label='Số hộ chiếu'
                  placeholder='Nhập số hộ chiếu'
                  labelProps={{ isRequired: true }}
                  rules={{
                    required: 'Chưa nhập số hộ chiếu',
                    validate: {
                      passportNumber: (value) => {
                        return validatePassportNumber(value);
                      },
                    },
                  }}
                />
              </div>
              <div className=''>
                <FormInputDate
                  name={`${fieldName}.expirationDate`}
                  label='Ngày hết hạn'
                  placeholder='dd/mm/yyyy'
                  labelProps={{ isRequired: true }}
                  rules={{
                    required: 'Chưa nhập ngày hết hạn',
                    validate: {
                      expireRule: (v: Date) => {
                        const sixMonth = new Date(
                          lastDepartDate + 2 * ONE_DAY_TIMESTAMP
                        ).setMonth(new Date(lastDepartDate).getMonth() + 6);
                        return (
                          !(v.getTime() < sixMonth) ||
                          ` Ngày hết hạn tối thiểu là ${formatDate(sixMonth)}`
                        );
                      },
                    },
                  }}
                />
              </div>
              <div className='col-span-2'>
                <FormCountry
                  name={`${fieldName}.idIssueCountry`}
                  label='Nơi cấp'
                  placeholder='Chọn'
                  labelProps={{ isRequired: true }}
                  inputProps={{
                    isOnlyValue: true,
                  }}
                  rules={{
                    required: 'Chưa chọn nơi cấp',
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default GetInfoByTypePassenger;
