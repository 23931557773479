import { Button, CloseIcon } from '@common-ui';
import { IBookingFlightData } from '@tixlabs/grpc-client/web-partner';
import { EModalMode } from '@web-admin/types';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import { useValidateBooking } from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useCurrency } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import {
  resetBooking,
  updateBookingInfo,
  updateCalculationFlightFee,
  updateContactInfo,
  updateInvoicingInformation,
  updatePassengersList,
} from '@web-booker/stores/reducers/booking';
import { IModal } from '@web-booker/types';
import { IFormBooking } from '@web-booker/types/ui/booking';
import { Modal } from 'antd';
import { SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ConfirmInfo from './ConfirmInfo';

export type TModalValidateBooking = IModal & {
  state: ESwitchModal;
};

export enum ESwitchModal {
  NONE = 'NONE',
  CONFIRM_INFO = 'CONFIRM_INFO',
  PRICE_CHANGED = 'PRICE_CHANGED',
  INVALID_INFO = 'INVALID_INFO',
}

type Props = {
  modalInfo: TModalValidateBooking;
  handleUpdateModalInfo: (value: SetStateAction<TModalValidateBooking>) => void;
};

const generateModalTitle = (state: ESwitchModal) => {
  const content = {
    [ESwitchModal.CONFIRM_INFO]: 'Kiểm tra thông tin',
  };
  return content[state];
};

export const ValidateBookingModal = ({
  modalInfo,
  handleUpdateModalInfo,
}: Props) => {
  const { formatPrice } = useCurrency();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const method = useFormContext<IFormBooking>();
  const { validateBooking, isLoadingValidate, validateResponse } =
    useValidateBooking();

  const {
    calculatedFlightFee: { totalPrice },
  } = useBooking();

  const handleSuccessValidate = (data: IBookingFlightData) => {
    dispatch(updateBookingInfo({ ...data }));
    dispatch(updateContactInfo(method.getValues('contactInfo')));
    dispatch(updatePassengersList(method.getValues('passengersList')));
    const invoiceInfo = method.getValues('invoicingInformation');

    if (method.getValues('isInvoicing') && invoiceInfo) {
      dispatch(updateInvoicingInformation(invoiceInfo));
    }

    handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
    navigate('../confirm');
  };

  const handlePriceChanged = () => {
    handleUpdateModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.PRICE_CHANGED,
      width: 450,
      closeIcon: false,
    }));
  };

  const handleInvalidInfo = () => {
    handleUpdateModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.INVALID_INFO,
    }));
  };

  const handleOk = async () => {
    const invoiceInfo = method.getValues('invoicingInformation');

    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_INFO:
        await validateBooking(
          method.watch(),
          handleSuccessValidate,
          handlePriceChanged,
          handleInvalidInfo
        );
        break;
      case ESwitchModal.PRICE_CHANGED:
        dispatch(updateBookingInfo({ ...validateResponse }));
        dispatch(updateContactInfo(method.getValues('contactInfo')));
        dispatch(updatePassengersList(method.getValues('passengersList')));

        if (method.getValues('isInvoicing') && invoiceInfo) {
          dispatch(updateInvoicingInformation(invoiceInfo));
        }
        validateResponse?.fee &&
          dispatch(updateCalculationFlightFee(validateResponse?.fee));
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate('../confirm');
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_INFO:
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.PRICE_CHANGED:
        dispatch(resetBooking());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate('../search');
        break;
      case ESwitchModal.INVALID_INFO:
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      default:
        break;
    }
  };

  const generateModalContent = (state: ESwitchModal) => {
    const content = {
      [ESwitchModal.CONFIRM_INFO]: <ConfirmInfo data={method.watch()} />,
      [ESwitchModal.PRICE_CHANGED]: (
        <ModalContent
          title='Giá đã thay đổi'
          content={
            <div className='space-y-5 w-[402px]'>
              <div className='text-center'>
                Giá vé có sự thay đổi từ hãng bay, vui lòng thông báo cho khách
                hàng.
              </div>
              <div className='space-y-3'>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold'>
                    Giá mới khách cần thanh toán
                  </div>
                  <div>
                    <span className='font-bold text-secondary text-xl'>
                      {formatPrice(validateResponse?.fee?.totalPrice || 0)}
                      {''}
                    </span>
                    <span> VND</span>
                  </div>
                </div>
                <div className='rounded bg-neutral-3 px-3 py-1.5'>
                  <div className='flex justify-between items-center'>
                    <div>Giá cũ</div>
                    <div>
                      <span className='text-md font-bold text-primary'>
                        {formatPrice(totalPrice)}{' '}
                      </span>
                      <span>VND</span>
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div>Chênh lệch</div>
                    <div>
                      <span className='text-md font-bold text-red-6'>
                        {formatPrice(
                          Math.abs(
                            (validateResponse?.fee?.totalPrice || 0) -
                              totalPrice
                          )
                        )}{' '}
                      </span>
                      <span>VND</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.INVALID_INFO]: (
        <ModalContent
          title='Trùng thông tin hành khách'
          content='Hành khách/hành trình bạn chọn bị trùng trong hệ thống.
          Vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
    };
    return content[state];
  };

  const generateModalFooter = (
    state: ESwitchModal,
    onOk: () => void,
    onCancel: () => void
  ) => {
    const content = {
      [ESwitchModal.CONFIRM_INFO]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoadingValidate}>
            Sửa thông tin
          </Button>
          <Button
            className='flex-1'
            onClick={onOk}
            disabled={isLoadingValidate}>
            Xác nhận & tiếp tục
          </Button>
        </div>
      ),
      [ESwitchModal.PRICE_CHANGED]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1' onClick={onCancel}>
            Tìm chuyến khác
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Đồng ý & tiếp tục
          </Button>
        </div>
      ),
      [ESwitchModal.INVALID_INFO]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1'>
            <a
              href='tel:+19008888'
              className='w-full visited:hover:active:text-inherit'>
              Gọi CSKH: 1900.8888
            </a>
          </Button>
          <Button className='flex-1'>
            <a
              href='tel:+19008888'
              className='w-full visited:hover:active:text-inherit'>
              Nhắn tin zalo
            </a>
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  return (
    <Modal
      {...modalInfo}
      closeIcon={
        modalInfo.closeIcon ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999] '
            onClick={handleCancel}
          />
        ) : null
      }
      title={generateModalTitle(modalInfo.state)}
      footer={generateModalFooter(modalInfo.state, handleOk, handleCancel)}>
      {generateModalContent(modalInfo.state)}
    </Modal>
  );
};

export default ValidateBookingModal;
