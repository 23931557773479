//Phu
export { ReactComponent as AdultIcon } from '../../assets/icons/adult.svg';
export { ReactComponent as AirPlaneIcon } from '../../assets/icons/airplane.svg';
export { ReactComponent as ArrowDownSLineIcon } from '../../assets/icons/arrow-down-s-line.svg';
export { ReactComponent as BabyIcon } from '../../assets/icons/baby.svg';
export { ReactComponent as BaggageHandIcon } from '../../assets/icons/baggage-hand.svg';
export { ReactComponent as BaggageIcon } from '../../assets/icons/baggage.svg';
export { ReactComponent as BriefCaseFill } from '../../assets/icons/brief-case-fill.svg';
export { ReactComponent as Calendar2LineIcon } from '../../assets/icons/calendar-2-line.svg';
export { ReactComponent as ChildIcon } from '../../assets/icons/child.svg';
export { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
export { ReactComponent as CrossLineIcon } from '../../assets/icons/cross-line.svg';
export { ReactComponent as DeleteBin6Line } from '../../assets/icons/delete-bin-6-line.svg';
export { ReactComponent as DollarIcon } from '../../assets/icons/dollar.svg';
export { ReactComponent as FileExcelLineIcon } from '../../assets/icons/file-excel-line.svg';
export { ReactComponent as FilterAirLineIcon } from '../../assets/icons/filter-airline.svg';
export { ReactComponent as DisplayModeIcon } from '../../assets/icons/filter-display-mode.svg';
export { ReactComponent as FilterFillIcon } from '../../assets/icons/filter-fill.svg';
export { ReactComponent as FlashLightFillIcon } from '../../assets/icons/flashlight-fill.svg';
export { ReactComponent as FlightLandingIcon } from '../../assets/icons/flight-landing.svg';
export { ReactComponent as FlightTakeOffIcon } from '../../assets/icons/flight-take-off.svg';
export { ReactComponent as GroupFillIcon } from '../../assets/icons/group-fill.svg';
export { ReactComponent as HistoryFillIcon } from '../../assets/icons/history-fill.svg';
export { ReactComponent as Home5LineIcon } from '../../assets/icons/home-5-line.svg';
export { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
export { ReactComponent as LogoSkyBookingIcon } from '../../assets/icons/logo-sky-booking.svg';
export { ReactComponent as LogoTripBookingIcon } from '../../assets/icons/logo-trip-booking.svg';
export { ReactComponent as MapPin2FillIcon } from '../../assets/icons/map-pin-2-fill.svg';
export { ReactComponent as MenuLineIcon } from '../../assets/icons/menu-line.svg';
export { ReactComponent as More2FillIcon } from '../../assets/icons/more-2-fill.svg';
export { ReactComponent as Notification3FillIcon } from '../../assets/icons/notification-3-fill.svg';
export { ReactComponent as PeopleFillIcon } from '../../assets/icons/people-fill.svg';
export { ReactComponent as PlaneFillIcon } from '../../assets/icons/plane-fill.svg';
export { ReactComponent as PlaneIcon } from '../../assets/icons/plane.svg';
export { ReactComponent as PlusLineIcon } from '../../assets/icons/plus-line.svg';
export { ReactComponent as ProfileFillIcon } from '../../assets/icons/profile-fill.svg';
export { ReactComponent as Refund2LineIcon } from '../../assets/icons/refund-2-line.svg';
export { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow-icon.svg';
export { ReactComponent as RingBellServiceIcon } from '../../assets/icons/ring-service.svg';
export { ReactComponent as SeatClassIcon } from '../../assets/icons/seat-class.svg';
export { ReactComponent as SeatIcon } from '../../assets/icons/seat.svg';
export { ReactComponent as SortDesc1to9Icon } from '../../assets/icons/sort-desc-1-9.svg';
export { ReactComponent as SortDescIcon } from '../../assets/icons/sort-desc.svg';
export { ReactComponent as SubLineIcon } from '../../assets/icons/sub-line.svg';
export { ReactComponent as SuitcaseFillIcon } from '../../assets/icons/suitcase-fill.svg';
export { ReactComponent as SwapFlightIcon } from '../../assets/icons/swap-flight.svg';
export { ReactComponent as Ticket2FillIcon } from '../../assets/icons/ticket-2-fill.svg';
export { ReactComponent as TimeFillIcon } from '../../assets/icons/time-fill.svg';
export { ReactComponent as MoneyDollarCircleLineIcon } from '../../assets/icons/money-dollar-circle-line.svg';

// Trong
export { ReactComponent as AddCircleIcon } from '../../assets/icons/add-circle-line.svg';
export { ReactComponent as AirPlane3FillIcon } from '../../assets/icons/air-plane-3-fill.svg';
export { ReactComponent as BookingIcon } from '../../assets/icons/booking.svg';
export { ReactComponent as CheckFillIcon } from '../../assets/icons/check-fill-icon.svg';
export { ReactComponent as CheckLineIcon } from '../../assets/icons/check-line.svg';
export { ReactComponent as ChooseAgainIcon } from '../../assets/icons/choose-again.svg';
export { ReactComponent as EyeOffLineIcon } from '../../assets/icons/eye-off-line.svg';
export { ReactComponent as EyeOnLineIcon } from '../../assets/icons/eye-on-line.svg';
export { ReactComponent as GroupLineIcon } from '../../assets/icons/group-line.svg';
export { ReactComponent as InformationFillIcon } from '../../assets/icons/information-fill.svg';
export { ReactComponent as InformationLineIcon } from '../../assets/icons/information-line.svg';
export { ReactComponent as ListSettingsFillIcon } from '../../assets/icons/list-settings-fill.svg';
export { ReactComponent as MailLineIcon } from '../../assets/icons/mail-line.svg';
export { ReactComponent as MoneyDollarCircleFillIcon } from '../../assets/icons/money-dollar-circle-fill.svg';
export { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg';
export { ReactComponent as PrintersFillIcon } from '../../assets/icons/printers-fill.svg';
export { ReactComponent as SearchLineIcon } from '../../assets/icons/search-line.svg';
export { ReactComponent as SortAscIcon } from '../../assets/icons/sort-asc.svg';
export { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg';

// Duy
export { ReactComponent as AccountCircleLineIcon } from '../../assets/icons/account-circle-line.svg';
export { ReactComponent as ArrowLeftSLineIcon } from '../../assets/icons/arrow-left-s-line.svg';
export { ReactComponent as ArrowRightSLineIcon } from '../../assets/icons/arrow-right-s-line.svg';
export { ReactComponent as ArticleLineIcon } from '../../assets/icons/article-line.svg';
export { ReactComponent as BarChartFillIcon } from '../../assets/icons/bar-chart-fill.svg';
export { ReactComponent as Calendar2FillIcon } from '../../assets/icons/calendar-2-fill.svg';
export { ReactComponent as CalendarCheckFillIcon } from '../../assets/icons/calendar-check-fill.svg';
export { ReactComponent as ClipBoardFillIcon } from '../../assets/icons/clipboard-fill.svg';
export { ReactComponent as CloseCircleIcon } from '../../assets/icons/close-circle-line.svg';
export { ReactComponent as ContactsBook2FillIcon } from '../../assets/icons/contact-book-2-fill.svg';
export { ReactComponent as Edit2FillIcon } from '../../assets/icons/edit-2-fill.svg';
export { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
export { ReactComponent as ForbidFillIcon } from '../../assets/icons/forbid-fill.svg';
export { ReactComponent as HandbagLineIcon } from '../../assets/icons/handbag-line.svg';
export { ReactComponent as LockPasswordFillIcon } from '../../assets/icons/lock-password-fill.svg';
export { ReactComponent as LogoutBoxRLineIcon } from '../../assets/icons/logout-box-r-line.svg';
export { ReactComponent as PencilFillIcon } from '../../assets/icons/pencil-fill.svg';
export { ReactComponent as SendPlaneFillIcon } from '../../assets/icons/send-plane-fill.svg';
export { ReactComponent as Spam2LineIcon } from '../../assets/icons/spam-2-line.svg';
export { ReactComponent as StarSFillIcon } from '../../assets/icons/star-s-fill.svg';
export { ReactComponent as StatusInProcessIcon } from '../../assets/icons/status-in-process.svg';
export { ReactComponent as StatusNewIcon } from '../../assets/icons/status-new.svg';
export { ReactComponent as TimeLineIcon } from '../../assets/icons/time-line.svg';
export { ReactComponent as User2FillIcon } from '../../assets/icons/user-2-fill.svg';
export { ReactComponent as User3FillIcon } from '../../assets/icons/user-3-fill.svg';
export { ReactComponent as UserAddFillIcon } from '../../assets/icons/user-add-fill.svg';
export { ReactComponent as Wechat2LineIcon } from '../../assets/icons/wechat-2-line.svg';
