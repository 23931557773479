import { CheckFillIcon, InformationFillIcon } from '@icon';
import { EModalMode } from '@web-admin/types';
import React from 'react';
import cn from 'classnames';

type Props = {
  title?: string;
  content?: React.ReactNode;
  mode: EModalMode;
  className?: string;
};

const IconModal = {
  [EModalMode.CONFIRM]: (
    <InformationFillIcon className='w-[64px] h-[64px] text-common-info' />
  ),
  [EModalMode.SUCCESS]: (
    <CheckFillIcon className='w-[64px] h-[64px] text-common-success' />
  ),
  [EModalMode.WARNING]: (
    <InformationFillIcon className='w-[64px] h-[64px] text-common-warning' />
  ),
  [EModalMode.ERROR]: (
    <InformationFillIcon className='w-[60px] h-[60px] text-common-warning' />
  ),
};

const ModalContent = ({ title, content, mode, className }: Props) => {
  return (
    <div className={cn('flex flex-col items-center w-[380px]', className)}>
      {mode !== EModalMode.ACTION && (
        <div className='flex flex-col items-center space-y-4'>
          {IconModal[mode]}
          <span className='text-lg font-semibold'>{title}</span>
        </div>
      )}
      {typeof content === 'function' ? (
        content
      ) : (
        <span className='text-base text-neutral-8 text-center'>{content}</span>
      )}
    </div>
  );
};

export default ModalContent;
