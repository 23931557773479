import { useAppSelector } from '../../stores/index';
export const useUserStore = () => {
  const { isFetching, isLogin, userData } = useAppSelector(
    (state) => state.user
  );

  return { isFetching, isLogin, userData };
};

export default useUserStore;
