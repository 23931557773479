// source: base/enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.base.OrderSubType', null, global);
goog.exportSymbol('proto.base.PaymentMethod', null, global);
goog.exportSymbol('proto.base.TransactionType', null, global);
/**
 * @enum {number}
 */
proto.base.PaymentMethod = {
  METHODNONE: 0,
  PAYPAL: 1,
  VNPAYEWALLET: 2,
  VNPAYCREDITCARD: 3,
  VNPAYBANKTRANSFER: 4,
  VNPAYQRCODE: 5,
  MANUALBANKTRANSFER: 6,
  MOMO: 7,
  TWOCHECKOUT: 8,
  PAYOOCREDITCARD: 9,
  PAYOODOMESTICCARD: 10,
  PAYOOQRCODE: 11,
  ONEPAYCREDITCARD: 12,
  ONEPAYCREDITCARDDOMESTICISSUE: 13,
  ONEPAYDOMESTICCARD: 14,
  ONEPAYQRCODE: 15,
  APPLEPAY: 16,
  STRIPE: 17,
  BIDVQR: 18,
  EGIFTCARD: 19,
  WALLET: 20
};

/**
 * @enum {number}
 */
proto.base.OrderSubType = {
  INVALID: 0,
  BUYGIFTCARD: 1,
  BUYBRANDCARD: 2,
  PHONETOPUPOTHER: 3,
  PHONETOPUPSELF: 4
};

/**
 * @enum {number}
 */
proto.base.TransactionType = {
  TRANSACTIONTYPEINVALID: 0,
  TOPUP: 1,
  REFUND: 2
};

goog.object.extend(exports, proto.base);
