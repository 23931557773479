import {
  IFareCheckData,
  IFlightClass,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppSelector } from '@web-booker/stores';
import { useAppMutation } from '../internals';

export function useFareCheck() {
  const { searchDataHistoryFullData } = useAppSelector(
    (state) => state.searchFlight
  );

  const {
    mutateAsync: getFareCheck,
    isLoading,
    data: fareData,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'fareCheck'],
    mutationFn: flightApiService.fareCheck,
  });

  async function fetchFareCheck(
    data: IFlightClass
  ): Promise<IFareCheckData | undefined> {
    try {
      if (searchDataHistoryFullData.key) {
        const res = await getFareCheck({
          flightId: data.validFlightClassData?.flightId || data.flightId,
          key: searchDataHistoryFullData.key,
        });

        if (res.isSuccess && res.data) {
          return res.data;
        }

        throw new Error();
      }
    } catch (error) {
      return undefined;
    }
  }

  return {
    fetchFareCheck,
    fareCheckData: fareData?.data,
    isLoading,
  };
}

export default useFareCheck;
