import {
  IFlightInfo,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';

import useBooking from '@web-booker/hooks/apps/useBooking';
import { useAppMutation } from '@web-booker/hooks/internals';
import useAirport from '@web-booker/hooks/stores/useAirport';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  addSearchDataHistory,
  saveSearchDataHistoryFullData,
  updateLoading,
} from '@web-booker/stores/reducers/searchFlight';
import { syncSearchHistory } from '@web-booker/stores/reducers/searchFlightHistory';
import { ESortType, EStopPointType } from '@web-booker/types/ui/flight';
import { customFlightInfo } from '@web-booker/utils';
import { useEffect, useMemo } from 'react';

function useFlightList() {
  const {
    requestCount,
    filter,
    filterClient,
    sortClient,
    pagination,
    sortsList,
    searchDataHistory,
    currentSelectIndex,
    itineraryType,
    searchDataHistoryFullData,
    isLoading,
  } = useAppSelector((state) => state.searchFlight);

  const { flightDataList } = useBooking();

  const dispatch = useAppDispatch();
  const { fetchAirports } = useAirport();

  const {
    mutateAsync: searchFlight,
    isLoading: isLoadingSearchFlight,
    // data: flightList,
    isIdle,
    isPaused,
    isSuccess,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'searchFlight'],
    mutationFn: flightApiService.searchFlight,
    onSuccess(data) {
      const objectAirportCode = {};

      if (data.itemsList) {
        data.itemsList.forEach((item) => {
          item.segmentsList.forEach((sItem) => {
            objectAirportCode[sItem.departPlace] = true;
            objectAirportCode[sItem.arrivalPlace] = true;
          });
        });

        const listCode = Object.keys(objectAirportCode);
        if (listCode.length) {
          fetchAirports(listCode);
        }
      }
    },
  });

  async function fetchData() {
    try {
      const prevSelectedFlight = flightDataList[currentSelectIndex - 1];
      const prevHistoryData = searchDataHistory[currentSelectIndex - 1];
      // console.log('prevSelectedFlight', prevSelectedFlight);
      // console.log('prevHistoryData', prevHistoryData);

      if (
        prevSelectedFlight?.classesList?.[0]?.nextItineraryKeyList?.length > 0
      ) {
        const nextItemList: IFlightInfo[] = [];

        prevSelectedFlight.classesList?.[0]?.nextItineraryKeyList.forEach(
          (nextItineraryKey) => {
            let nextData = Object.assign(
              {},
              searchDataHistoryFullData.itemMaps?.get(nextItineraryKey)
            );

            nextData = customFlightInfo(nextData, prevSelectedFlight);

            if (nextData?.classesList?.length) {
              nextItemList.push(nextData);
            }
          }
        );

        dispatch(
          addSearchDataHistory({
            ...prevHistoryData,
            itemsList: nextItemList,
          })
        );
        return;
      }

      if (isLoadingSearchFlight || isLoading) {
        return;
      }
      dispatch(updateLoading(true));
      const resData = await searchFlight({
        filter: {
          ...filter,
          currency: 'VND',
        },
        sortsList: sortsList,
        pagination: pagination,
      });
      const itemMapsData = new Map();
      resData.itemsList.forEach((item) => {
        itemMapsData.set(item.itineraryKey, item);
      });

      dispatch(
        saveSearchDataHistoryFullData({
          ...resData,
          itemMaps: itemMapsData,
        })
      );

      dispatch(
        addSearchDataHistory({
          ...resData,
          itemsList: resData.itemsList
            .filter((item) => item.isFirstFlight)
            .map((item) => customFlightInfo(item)),
        })
      );
      dispatch(syncSearchHistory());
    } catch (error) {
      console.log('error', { error });
    } finally {
      dispatch(updateLoading(false));
    }
  }

  function matchFilterClient(flightInfo: IFlightInfo) {
    // filterClient.landingTimes
    const currentStopPoint =
      flightInfo.segmentsList.length === 1
        ? EStopPointType.STOP_POINT_TYPE_NONE_STOP
        : flightInfo.segmentsList.length === 2
        ? EStopPointType.STOP_POINT_TYPE_ONE
        : EStopPointType.STOP_POINT_TYPE_OVER_TWO;

    let isMatchAirline = true;
    let isMatchStopPointType = true;
    let isMatchTakeOffRanges = true;
    let isMatchLandingTimes = true;
    let isMatchTicketClass = true;

    if (filterClient.airlines.length) {
      isMatchAirline = filterClient.airlines.includes(
        flightInfo.carrierMarketing
      );
    }

    if (filterClient.stopPointType.length) {
      isMatchStopPointType =
        filterClient.stopPointType.includes(currentStopPoint);
    }

    if (filterClient.takeOffRanges.length) {
      const departTime = new Date(flightInfo.departDate);
      const totalMinuteInDay =
        departTime.getUTCHours() * 60 + departTime.getUTCMinutes();
      isMatchTakeOffRanges = filterClient.takeOffRanges.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.landingTimes.length) {
      const arrivalTime = new Date(flightInfo.arrivalDate);
      const totalMinuteInDay =
        arrivalTime.getUTCHours() * 60 + arrivalTime.getUTCMinutes();
      isMatchLandingTimes = filterClient.landingTimes.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.ticketClass.length) {
      isMatchTicketClass = filterClient.ticketClass.some((classFilter) => {
        return flightInfo.classesList.some(
          (classItem) => classItem.cabinClass === classFilter
        );
      });
    }

    return (
      isMatchAirline &&
      isMatchStopPointType &&
      isMatchTakeOffRanges &&
      isMatchLandingTimes &&
      isMatchTicketClass
    );
  }

  function sortRule(a: IFlightInfo, b: IFlightInfo) {
    const direction = sortClient.asc ? 1 : -1;
    let result = 0;
    const priceDataA =
      a.classesList[0]?.validFlightClassData?.displayPrice || 0;
    const priceDataB =
      b.classesList[0]?.validFlightClassData?.displayPrice || 0;

    switch (sortClient.type) {
      case ESortType.AIR_LINE:
        result = a.carrierMarketing.localeCompare(b.carrierMarketing);
        break;
      case ESortType.DEPART_DATE:
        result =
          new Date(a.departDate).getTime() - new Date(b.departDate).getTime();
        break;

      case ESortType.ARRIVAL_DATE:
        result =
          new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime();
        break;
      case ESortType.TICKET_PRICE:
        result = priceDataA - priceDataB;
        break;

      default:
    }

    return result * direction;
  }

  useEffect(() => {
    if (currentSelectIndex >= 0 && !searchDataHistory[currentSelectIndex]) {
      fetchData();
    }
  }, [currentSelectIndex, searchDataHistory]);

  const currentSearchData = useMemo(() => {
    const cloneSearchData = searchDataHistory[currentSelectIndex];

    return {
      ...cloneSearchData,
      itemsList:
        cloneSearchData?.itemsList?.filter(matchFilterClient).sort(sortRule) ||
        [],
    };
  }, [
    currentSelectIndex,
    searchDataHistory,
    filterClient,
    sortClient.asc,
    sortClient.type,
  ]);

  useEffect(() => {
    const stepBarEl = document.getElementById('search-step-bar');
    if (stepBarEl) {
      stepBarEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [searchDataHistoryFullData]);

  useEffect(() => {
    const selectedFlightEl = document.getElementById('selected-flight-info');
    if (selectedFlightEl) {
      selectedFlightEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentSelectIndex]);

  useEffect(() => {
    const sortFlightEl = document.getElementById('sort-flight');
    if (sortFlightEl) {
      sortFlightEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [
    filterClient.airlines.length,
    filterClient.landingTimes.length,
    filterClient.stopPointType.length,
    filterClient.takeOffRanges.length,
    filterClient.ticketClass.length,
  ]);

  return {
    currentSearchData,
    currentSelectIndex,
    isLoading: isLoadingSearchFlight || isLoading,
    isIdle,
  };
}

export default useFlightList;
