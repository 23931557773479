import { SESSION_STORAGE_KEY, getSessionStorageData } from '@core/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import tixlabsAxiosService from '@tixlabs/axios-client/axios.tixlabs';

import {
  ILoginForm,
  IWalletBasic,
} from '@tixlabs/grpc-client/web-partner-admin';
import { IUserInfo } from '@tixlabs/grpc-client';

import { JwtTokenTixlabs } from '@tixlabs/jwt';

const accessTokenTD: string | null = getSessionStorageData<string>(
  SESSION_STORAGE_KEY.ACCESS_TOKEN
);
const refreshTokenTD: string | null = getSessionStorageData<string>(
  SESSION_STORAGE_KEY.REFRESH_TOKEN
);

export const fetchUserData = createAsyncThunk(
  'users/data',
  async (_, { dispatch }) => {
    try {
      const { isSuccess, data, errorCode } = await (
        await import(
          '@tixlabs/grpc-client/web-partner-admin/partner-user-service-api'
        )
      ).partnerUserServiceClient.getMe();
      if (isSuccess && data) {
        await dispatch(syncWalletBallance()).unwrap();
        return {
          ...data,
        };
      } else {
        throw new Error(errorCode);
      }
    } catch (errors) {
      return Promise.reject(errors);
    }
  }
);

export const logout = createAsyncThunk(
  'users/logout',
  async (_, { dispatch }) => {
    try {
      dispatch(resetWalletBallance());
      await (
        await import('@tixlabs/grpc-client/web-partner-admin/auth-api')
      ).authServiceClientApi
        .logout()
        .catch(() => {
          //
        });
    } catch (error) {}
  }
);

export const userAuthenticate = createAsyncThunk(
  'users/authen',
  async (params: ILoginForm, { dispatch, rejectWithValue }) => {
    try {
      const { errorCode, isSuccess, data } = await (
        await import('@tixlabs/grpc-client/web-partner-admin/auth-api')
      ).authServiceClientApi.login(params);
      if (isSuccess && data?.accessToken && data.refreshToken) {
        const jwtTixLabs = new JwtTokenTixlabs(
          data.accessToken,
          data.refreshToken
        );
        jwtTixLabs.startTimerRefreshToken();
        await dispatch(fetchUserData()).unwrap();
        tixlabsAxiosService.setToken(`Bearer ${data.accessToken}`);
        return null;
      } else {
        throw new Error('Authenticate fail!');
      }
    } catch (errors) {
      await dispatch(logout());
      return rejectWithValue(errors);
    }
  }
);

export const checkAuthenticate = createAsyncThunk(
  'user/checkAuthen',
  async (_, { dispatch }) => {
    try {
      if (accessTokenTD && refreshTokenTD) {
        const jwtTixLabs = new JwtTokenTixlabs(accessTokenTD, refreshTokenTD);

        if (jwtTixLabs.isRefreshTokenExpired()) {
          throw new Error('Refresh token has expired!');
        }
        jwtTixLabs.startTimerRefreshToken();
        await dispatch(fetchUserData()).unwrap();
        tixlabsAxiosService.setToken(`Bearer ${accessTokenTD}`);

        return true;
      } else {
        throw new Error('Authenticate fail!');
      }
    } catch (errors) {
      await dispatch(logout());
      return Promise.reject(errors);
    }
  }
);

export const syncWalletBallance = createAsyncThunk('user/wallet', async () => {
  try {
    const { data, isSuccess, errorCode } = await (
      await import('@tixlabs/grpc-client/web-partner-admin/wallet-api')
    ).walletApiService.retrieveBalance();
    if (data && isSuccess) {
      return data;
    } else {
      throw errorCode;
    }
  } catch (errors) {
    return Promise.reject(errors);
  }
});

export interface UserState {
  userData: IUserInfo | null;
  isFetching: boolean;
  isLogin: boolean;
  walletBallance: number;
}

const initialUserState: UserState = {
  userData: null,
  isFetching: !!accessTokenTD,
  isLogin: !!accessTokenTD,
  walletBallance: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateUserData: (state, action: PayloadAction<Partial<IUserInfo>>) => {
      if (state.userData) {
        state.userData = { ...state.userData, ...action.payload };
      }
    },
    setUserData: (state, action: PayloadAction<IUserInfo>) => {
      state.userData = { ...action.payload };
    },
    resetWalletBallance: (state) => {
      state.walletBallance = 0;
    },
  },
  extraReducers: {
    [userAuthenticate.pending.toString()]: (state) => {
      state.isFetching = true;
    },
    [userAuthenticate.fulfilled.toString()]: (state) => {
      state.isFetching = false;
      state.isLogin = true;
    },
    [userAuthenticate.rejected.toString()]: (state) => {
      state.isFetching = false;
      state.isLogin = false;
    },

    [fetchUserData.pending.toString()]: (state) => {
      // state.isFetching = true;
    },
    [fetchUserData.fulfilled.toString()]: (
      state: UserState,
      action: PayloadAction<IUserInfo>
    ) => {
      // state.isFetching = false;
      if (action.payload) {
        state.userData = { ...action.payload };
      }
    },
    [fetchUserData.rejected.toString()]: (state) => {
      // state.isFetching = false;
      state.userData = null;
      state.isLogin = false;
    },

    [checkAuthenticate.pending.toString()]: (state) => {
      state.isFetching = true;
    },
    [checkAuthenticate.fulfilled.toString()]: (state) => {
      state.isFetching = !state.userData;
      state.isLogin = true;
    },
    [checkAuthenticate.rejected.toString()]: (state) => {
      state.isFetching = false;
      state.isLogin = false;
    },

    [syncWalletBallance.pending.toString()]: (state) => {
      state.isFetching = true;
    },
    [syncWalletBallance.fulfilled.toString()]: (
      state: UserState,
      action: PayloadAction<IWalletBasic>
    ) => {
      state.isFetching = !state.userData;
      state.walletBallance = action.payload.balance;
    },
    [syncWalletBallance.rejected.toString()]: (state) => {
      state.isFetching = false;
    },

    [logout.fulfilled.toString()]: (state) => {
      state.userData = null;
      state.isLogin = false;
      window.localStorage.removeItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);
      window.localStorage.removeItem(SESSION_STORAGE_KEY.REFRESH_TOKEN);
      window.sessionStorage.removeItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);
      window.sessionStorage.removeItem(SESSION_STORAGE_KEY.REFRESH_TOKEN);
    },
    [logout.rejected.toString()]: (state) => {
      state.userData = null;
      state.isLogin = false;
      window.localStorage.removeItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);
      window.localStorage.removeItem(SESSION_STORAGE_KEY.REFRESH_TOKEN);
      window.sessionStorage.removeItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);
      window.sessionStorage.removeItem(SESSION_STORAGE_KEY.REFRESH_TOKEN);
    },
  },
});

export const { updateUserData, setUserData, resetWalletBallance } =
  userSlice.actions;

export default userSlice.reducer;
