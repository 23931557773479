import { Card, MoneyDollarCircleFillIcon } from '@common-ui';
import {
  ICalculationFlightFeeRes,
  IItemCalculationFlightFee,
} from '@tixlabs/grpc-client';
import { IBookingPassenger } from '@tixlabs/grpc-client/web-partner';

import { useCurrency } from '@web-booker/hooks/internals';
import { PAX_TYPE_SHORT_LABEL } from '@web-booker/utils';

type Props = {
  fee: ICalculationFlightFeeRes;
  passengersList: IBookingPassenger[];
};

function PriceItem({
  label,
  ...rest
}: {
  label: string;
} & IItemCalculationFlightFee) {
  const { formatPrice } = useCurrency();
  const {
    quantity,
    fareBasic,
    taxAmount,
    vat,
    serviceFee,
    totalPrice,
    totalPricePerPassenger,
  } = rest;
  return (
    <tr>
      <td align='left' className='uppercase'>
        {label}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(fareBasic)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(taxAmount)}
      </td>
      {/* <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(vat || 0)}
      </td> */}
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(serviceFee)}
      </td>
      <td className='text-secondary-6 font-semibold' align='right'>
        {formatPrice(totalPricePerPassenger)}
      </td>
    </tr>
  );
}

export const BookingPriceTicketInfo = ({ fee, passengersList }: Props) => {
  const { itemsList } = fee;

  return (
    <Card
      title={'Thông tin giá vé'}
      classNameTitle='border-b-0'
      className='w-full border-0'
      classNameChildren='p-2.5 '
      icon={<MoneyDollarCircleFillIcon />}>
      <table className='table-auto w-full view-table'>
        <thead>
          <tr>
            <th align='left'>Hành khách</th>
            <th align='right'>Giá vé</th>
            <th align='right'>Thuế và phí</th>
            {/* <th align='right'>VAT</th> */}
            <th align='right'>Phí dịch vụ</th>
            <th align='right'>Tổng giá</th>
          </tr>
        </thead>
        <tbody>
          {passengersList.map((item, index) => {
            const priceInfo = itemsList.find((p) => p.type === item.type);
            return (
              priceInfo && (
                <PriceItem
                  key={index}
                  label={`${item.lastName} ${item.firstName} (${
                    PAX_TYPE_SHORT_LABEL[item.type]
                  })`}
                  {...priceInfo}
                />
              )
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default BookingPriceTicketInfo;
