import { Button, Form } from '@common-ui';
import { EPassengerType } from '@tixlabs/types';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  updateContactInfo,
  updateInvoicing,
  updateInvoicingInformation,
  updatePassengersList,
} from '@web-booker/stores/reducers/booking';
import { IFormBooking, IPassengerForm } from '@web-booker/types/ui/booking';
import { DEFAULT_MODAL_CONTENT } from '@web-booker/utils';
import { DEFAULT_PASSENGER_INFO } from '@web-booker/utils/constants/booking';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputContactInfoCtn from './InputContactInfo';
import InputInvoiceInfo from './InputInvoiceInfo';
import InputPassengerInfoCtn from './InputPassengerInfo';
import ValidateBookingModal, {
  ESwitchModal,
  TModalValidateBooking,
} from './Modal/ValidateBookingModal';

type Props = {
  //
};

const DEFAULT_MODAL_INFO: TModalValidateBooking = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
  closeIcon: true,
};

export const InputBookingFormCtn = (props: Props) => {
  const navigate = useNavigate();
  const {
    passengerQuantity,
    contactInfo,
    passengersList,
    invoicingInformation,
    flightDataList,
    isInvoiceInfo,
  } = useBooking();

  const dispatch = useAppDispatch();

  const methods = useForm<IFormBooking>({
    defaultValues: {
      passengersList:
        passengersList?.map((item) => ({
          ...item,
          dob: item.dob ? new Date(item.dob) : null,
          expirationDate: item.expirationDate
            ? new Date(item.expirationDate)
            : null,
        })) || [],
      contactInfo: contactInfo || {
        title: '',
        firstName: '',
        lastName: '',
        phoneCode: '+84',
        phoneNumber: '',
        email: '',
        address: '',
        note: '',
      },
      invoicingInformation: isInvoiceInfo
        ? invoicingInformation
        : {
            companyInfo: {
              address: '',
              name: '',
              taxCode: '',
            },
            receiverInfo: {
              address: '',
              email: '',
              name: '',
              note: '',
              phone: '',
            },
          },
      isInvoicing: isInvoiceInfo,
      // contactInfo: {
      //   title: 'mr',
      //   firstName: 'ho',
      //   lastName: 'Phu',
      //   phoneCode: '+84',
      //   phoneNumber: '',
      //   email: 'sp@gmail.com',
      //   address: '325 bac hdang',
      //   note: 'note',
      // },
      // invoicingInformation: {
      //   companyInfo: {
      //     address: '325 bbbb',
      //     name: 'company x',
      //     taxCode: '123123412',
      //   },
      //   receiverInfo: {
      //     address: 'dia chi ne',
      //     email: 'sp@gmail.com',
      //     name: 'name nguoi nhan ne',
      //     note: 'noite nè',
      //     phone: '070820929287',
      //   },
      // },
      // isInvoicing: true,
    },
  });

  const {
    formState: { errors },
    watch,
  } = methods;
  const isInvoicingValue = watch('isInvoicing');

  const [modalInfo, setModalInfo] =
    useState<TModalValidateBooking>(DEFAULT_MODAL_INFO);

  const {
    filter: { flightsList },
  } = useAppSelector((state) => state.searchFlight);

  const addFormDefaultValue = () => {
    try {
      let tempArray: IPassengerForm[] = [];
      const cloneDefaultPassenger = { ...DEFAULT_PASSENGER_INFO };

      if (passengerQuantity?.adt) {
        for (let i = 0; i < passengerQuantity.adt; i++) {
          tempArray = [
            ...tempArray,
            {
              ...cloneDefaultPassenger,
              type: EPassengerType.ADT,
            },
          ];
        }
      }
      if (passengerQuantity?.chd) {
        for (let i = 0; i < passengerQuantity.chd; i++) {
          tempArray = [
            ...tempArray,
            {
              ...cloneDefaultPassenger,
              type: EPassengerType.CHD,
            },
          ];
        }
      }
      if (passengerQuantity?.inf) {
        for (let i = 0; i < passengerQuantity?.inf; i++) {
          tempArray = [
            ...tempArray,
            {
              ...cloneDefaultPassenger,
              type: EPassengerType.INF,
            },
          ];
        }
      }
      methods.setValue('passengersList', tempArray);
      dispatch(updatePassengersList(tempArray));
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleValidateBooking = async (data: IFormBooking) => {
    // SAVE CURRENT INFO
    dispatch(updateContactInfo(data.contactInfo));
    dispatch(updatePassengersList(data.passengersList));
    if (data.invoicingInformation && data.isInvoicing) {
      dispatch(updateInvoicingInformation(data.invoicingInformation));
    } else {
      dispatch(updateInvoicing(false));
    }

    setModalInfo((pre) => ({
      ...pre,
      open: true,
      width: 500,
      state: ESwitchModal.CONFIRM_INFO,
    }));
  };

  useEffect(() => {
    if (!passengersList.length) {
      addFormDefaultValue();
    }
  }, [passengerQuantity, flightDataList]);

  useEffect(() => {
    if (
      flightDataList.length !== flightsList?.length ||
      !flightDataList.length
    ) {
      navigate('/booking-flight/search');
    }
  }, [flightDataList?.length, flightsList?.length]);

  useEffect(() => {
    if (!isInvoicingValue) {
      methods.clearErrors('invoicingInformation');
    }
  }, [isInvoicingValue]);

  return (
    <Form
      methods={methods}
      onSubmit={handleValidateBooking}
      className='flex flex-col space-y-5'>
      <InputPassengerInfoCtn />
      <InputContactInfoCtn />
      <InputInvoiceInfo />
      <div className='flex justify-end'>
        <Button
          type='submit'
          className=' px-5 py-2.5'
          disabled={methods.formState.isSubmitting}>
          Kiểm tra thông tin
        </Button>
      </div>
      <ValidateBookingModal
        handleUpdateModalInfo={setModalInfo}
        modalInfo={modalInfo}
      />
    </Form>
  );
};

export default InputBookingFormCtn;
