import { IAirportInfo } from '@tixlabs/grpc-client/web-partner';
import InputSearchAirport, {
  InputSearchAirportProps,
} from '../UI/InputSearchAirport';
import { TextField, TextFieldProps } from '@common-ui';

export function FormInputSearchAirport({
  inputProps = {},
  handleSearch,
  groupFlightData,
  mode,
  ...rest
}: TextFieldProps &
  Pick<InputSearchAirportProps, 'handleSearch' | 'groupFlightData' | 'mode'> & {
    inputProps?: Pick<
      InputSearchAirportProps,
      'className' | 'disabled' | 'inputSize' | 'inputSearchClassName'
    >;
  }) {
  return (
    <TextField {...rest}>
      {({ value, ...rest }) => (
        <InputSearchAirport
          {...inputProps}
          {...rest}
          handleSearch={handleSearch}
          groupFlightData={groupFlightData}
          mode={mode}
          value={value as IAirportInfo}
        />
      )}
    </TextField>
  );
}

export default FormInputSearchAirport;
