import { Card, FilterFillIcon, Form } from '@common-ui';
import { EStopPointType, IFormFilterFlight } from '@web-booker/types/ui/flight';
import { defaultFilterFlight } from '@web-booker/utils/constants/flight';
import { useForm } from 'react-hook-form';
import AirlineBrand from './components/AirlineBrand';
// import DisplayMode from './components/DisplayMode';
import { Landing } from './components/Landing';
import StopPoint from './components/StopPoint';
import TakeOff from './components/TakeOff';
// import TicketClass from './components/TicketClass';

import { useAirlines } from '@web-booker/hooks/stores';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  TFilterClient,
  updateFilterClient,
} from '@web-booker/stores/reducers/searchFlight';
import { useEffect } from 'react';
import DisplayMode from './components/DisplayMode';
import TicketClass from './components/TicketClass';

type Props = {
  //
};

export const FilterFlightCtn = (props: Props) => {
  const { searchDataHistory, currentSelectIndex } = useAppSelector(
    (state) => state.searchFlight
  );
  const dispatch = useAppDispatch();
  const { getAirlineNameByCode } = useAirlines();
  const methods = useForm<IFormFilterFlight>({
    defaultValues: defaultFilterFlight,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    watch,
    formState: { errors },
    handleSubmit,
  } = methods;
  // const airlinesData = watch('airlines');

  const handleSubmitForm = (data: IFormFilterFlight) => {
    dispatch(
      updateFilterClient({
        airlines: data.airlines.reduce<string[]>((current, item) => {
          if (item.isSelected) {
            return [...current, item.value];
          }

          return current;
        }, []),
        takeOffRanges: data.takeOffRanges.reduce<
          TFilterClient['takeOffRanges']
        >((current, item) => {
          if (item.isSelected) {
            return [...current, item.value];
          }

          return current;
        }, []),
        landingTimes: data.landingTimes.reduce<TFilterClient['landingTimes']>(
          (current, item) => {
            if (item.isSelected) {
              return [...current, item.value];
            }

            return current;
          },
          []
        ),
        stopPointType: data.stopPointType.reduce<EStopPointType[]>(
          (current, item) => {
            if (item.isSelected) {
              return [...current, item.value];
            }

            return current;
          },
          []
        ),
        displayMode: data.displayMode,
        ticketClass: data.ticketClass.reduce<string[]>((current, item) => {
          if (item.isSelected) {
            return [...current, item.value];
          }

          return current;
        }, []),
      })
    );
  };

  function genFilter() {
    const currentFlightList = searchDataHistory[currentSelectIndex];
    const airlinesList = [
      ...new Set(
        currentFlightList.itemsList.map((item) => {
          return item.carrierMarketing;
        })
      ),
    ];

    methods.setValue(
      'airlines',
      airlinesList.map((key) => ({
        label: getAirlineNameByCode(key),
        value: key,
        isSelected: false,
      })) || []
    );

    const tempSetOfTicketClass = new Set<string>();
    currentFlightList.itemsList.forEach((flight) => {
      flight.classesList.forEach((ticketClass) => {
        tempSetOfTicketClass.add(ticketClass.cabinClass);
      });
    });
    const ticketClassList = [...tempSetOfTicketClass];
    methods.setValue(
      'ticketClass',
      ticketClassList.map((ticketClass) => ({
        label: ticketClass,
        value: ticketClass,
        isSelected: false,
      }))
    );

    methods.setValue('landingTimes', defaultFilterFlight.landingTimes);
    methods.setValue('takeOffRanges', defaultFilterFlight.takeOffRanges);
    methods.resetField('stopPointType');
  }

  useEffect(() => {
    if (searchDataHistory?.[currentSelectIndex]?.itemsList) {
      genFilter();
    }
  }, [searchDataHistory, currentSelectIndex]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(handleSubmitForm)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <Form onSubmit={handleSubmitForm} methods={methods}>
      <Card
        icon={<FilterFillIcon className='w-6 h-6' />}
        title='Bộ lọc chuyến bay'
        classNameChildren='p-2.5 flex flex-col space-y-2.5 overflow-y-auto max-h-[80vh] filter-flight'>
        <div className='shrink-0'>
          <DisplayMode />
        </div>
        <AirlineBrand />
        <div className='shrink-0'>
          <StopPoint />
        </div>
        <div className='shrink-0'>
          <TakeOff />
        </div>
        <div className='shrink-0'>
          <Landing />
        </div>
        <TicketClass />
      </Card>
    </Form>
  );
};

export default FilterFlightCtn;
