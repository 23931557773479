import { Button, Card, FlashLightFillIcon, Textarea } from '@common-ui';
import { cleanString } from '@core/utils';
import { EGender } from '@tixlabs/types';

import { useCountry } from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { IFormBooking, IPassengerForm } from '@web-booker/types/ui/booking';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  //
};

function getName(fullName: string): { firstName: string; lastName: string } {
  if (!fullName) {
    return {
      firstName: '',
      lastName: '',
    };
  }

  const [lastName, ...firstNameWords] = fullName.toUpperCase().split(' ');
  return {
    firstName: firstNameWords.join(' '),
    lastName: lastName,
  };
}

function getDate(dateString: string): Date | null {
  try {
    if (!dateString) {
      throw new Error();
    }

    const pattern = /^(\d{2})\/*(\d{2})\/*(\d{4})$/;

    const arrayDate = dateString.trim().match(pattern);
    if (arrayDate && arrayDate.length) {
      // eslint-disable-next-line prefer-const
      let [_, day, month, year] = arrayDate.map((item) => parseInt(item));
      // console.log(day, month, year);
      if (month > 12 && day <= 12) {
        [day, month] = [month, day];
      }
      const resultDate = new Date(year, month - 1, day);

      if (resultDate.toDateString() === 'Invalid Date') {
        throw new Error();
      }
      return resultDate;
    }
    throw new Error();
  } catch (error) {
    return null;
  }
}

function getGender(genderString: string): EGender {
  const maleWords = [
    'mr',
    'ong',
    'nam',
    'chu',
    'be trai',
    'anh',
    'men',
    'man',
    'mstr',
  ];
  const feMaleWords = [
    'mrs',
    'ms',
    'miss',
    'ba nu',
    'ba',
    'co',
    'di',
    'be gai',
    'chi',
    'gai',
    'women',
    'woman',
    'nu',
  ];
  const cleanValue = cleanString(genderString.toLocaleLowerCase());

  if (maleWords.includes(cleanValue)) {
    return EGender.GENDER_TYPE_MALE;
  }

  if (feMaleWords.includes(cleanValue)) {
    return EGender.GENDER_TYPE_FEMALE;
  }

  return EGender.GENDER_TYPE_NONE;
}

export const ShortHandInput = ({}: Props) => {
  const { passengersList, isOnlyDomesticBooking } = useBooking();
  const methods = useFormContext<IFormBooking>();
  const { countriesObjectByCode } = useCountry();

  const [shortHandValue, setShortHandValue] = useState('');

  function getPassengerData(inputData: string) {
    if (!inputData) return [];
    const listPassengerInfo = shortHandValue.split('\n');

    const listPassengerForm: IPassengerForm[] = [];

    let currentPassengerIndex = 0;
    listPassengerInfo.forEach((passengerInfo) => {
      if (passengerInfo) {
        // const
        const [
          gender,
          fullName,
          dob,
          nationality,
          passportNumber,
          expirationDate,
          idIssueCountry,
        ] = passengerInfo.split(',');
        const { firstName, lastName } = getName(cleanString(fullName));
        const passengerType = passengersList?.[currentPassengerIndex]?.type;
        // countryNameOptionsValueCode
        let _nationality = cleanString(nationality);
        if (!countriesObjectByCode?.[_nationality]) {
          _nationality = '';
        }

        let _idIssueCountry = cleanString(idIssueCountry);
        if (!countriesObjectByCode?.[_idIssueCountry]) {
          _idIssueCountry = '';
        }
        if (passengerType) {
          let newPassengerData: IPassengerForm = {
            gender: getGender(gender),
            firstName,
            lastName,
            dob: getDate(dob),
            type: passengerType,
            nationality: _nationality,
          };
          if (!isOnlyDomesticBooking) {
            newPassengerData = {
              ...newPassengerData,
              passportNumber: cleanString(passportNumber),
              expirationDate: getDate(expirationDate),
              idIssueCountry: _idIssueCountry,
            };
          }
          listPassengerForm.push(newPassengerData);
          currentPassengerIndex++;
        }
      }
    });
    if (listPassengerForm) {
      // methods.setValue('passengersList', listPassengerForm);
      listPassengerForm.forEach((item, index) => {
        methods.setValue(`passengersList.${index}`, item);
        methods.trigger(`passengersList.${index}`);
        methods.register(`passengersList.${index}`);
      });

      // dispatch(updatePassengersList(listPassengerForm));
    }
  }

  // useEffect(() => {
  //   getPassengerData(shortHandValue || '');
  // }, [shortHandValue]);

  return (
    <Card
      title='Nhập nhanh thông tin hành khác'
      className='!bg-[#F9F9F9]'
      classNameTitle='text-sm'
      defaultShow
      classNameChildren='p-2.5'>
      <div>
        <p className='mb-1'>
          Tính năng này cho phép phân tích và định dạng lại thông tin bạn nhập
          để tự động điền vào danh sách hành khách.
        </p>
        <p className='mb-1'>Nhập mỗi hành khách một dòng theo cấu trúc sau:</p>
        <ul className='list-disc list-inside mb-1'>
          <li className='marker:mr-2'>
            [Giới tính], [Họ và tên], [Ngày tháng năm sinh], [Quốc tịch], [Số
            Passport], [Ngày hết hạn Passport], [Nơi Cấp Passport];
          </li>
          <li>
            Loại khách, giới tính
            <ul className='list-disc list-inside pl-5'>
              <li>Mr, ông, anh (Người lớn, nam)</li>
              <li>Ms, bà, chị (Người lớn, nữ)</li>
              <li>Mstr, bé trai (Trẻ em, nam)</li>
              <li>Miss, bé gái (Trẻ em, nữ)</li>
            </ul>
          </li>
          <li>Định dạng ngày: ngày tháng năm (dd mm yyyy)</li>
        </ul>
        <p>Ví dụ: Anh, Nguyễn Văn Nam, 12031990, VN, C1234562, 07092026, VN</p>
        <Textarea
          rows={8}
          style={{
            resize: 'vertical',
          }}
          value={shortHandValue}
          onChange={(e) => setShortHandValue(e.target.value)}
          className='mb-1'
        />

        <Button
          theme='primary'
          variant='solid'
          rounded='md'
          // className='w-[167px]'
          size='md'
          onClick={() => {
            getPassengerData(shortHandValue);
          }}>
          <div className='inline-flex gap-x-2.5 items-center px-4 font-semibold'>
            <FlashLightFillIcon /> Thực hiện
          </div>
        </Button>
      </div>
    </Card>
  );
};

export default ShortHandInput;
