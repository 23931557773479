import { CustomAxiosResponse } from '@core/types';
import { ICity, ICountry } from '@tixlabs/types';
import storageService from '../axios.storage';

export const getCountries = (): Promise<
  CustomAxiosResponse<{ countrySupport: string[]; list: ICountry[] }>
> => {
  return storageService.get(`/countries/countries.json`);
};

export const getCities = (): Promise<
  CustomAxiosResponse<{ cities: ICity[] }>
> => {
  return storageService.get(`/countries/cities.json`);
};
