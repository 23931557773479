import { BasicRes, Empty } from '@api/base/base_pb';
import { AuthServiceClient } from '@api/partner/web_partner/auth_grpc_web_pb';
import { LoginReq, LoginRes } from '@api/partner/web_partner/auth_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class AuthApi extends BaseApi<AuthServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new AuthServiceClient(this.url, null, this.optsDev);
  }

  login = ({
    agentCode,
    password,
    userName,
  }: LoginReq.AsObject): Promise<LoginRes.AsObject> => {
    const req = new LoginReq();
    req.setAgentCode(agentCode);
    req.setUserName(userName);
    req.setPassword(password);
    return this.grpc<LoginReq, LoginRes, LoginRes.AsObject>(
      this.serviceClient.login,
      req,
      {}
    );
  };
  logout = (): Promise<BasicRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, BasicRes, BasicRes.AsObject>(
      this.serviceClient.logout,
      req,
      {
        noNoty: true,
      }
    );
  };
}

export const authServiceClientApi = new AuthApi();
export default authServiceClientApi;
