import { EBookingStatus, EPaxType } from '@tixlabs/grpc-client';
import { EGender, EPassengerType } from '@tixlabs/types';
import { IPassengerForm } from '@web-booker/types/ui/booking';

export enum EPronoun {
  MALE = 'mr',
  FEMALE = 'mrs',
}

export const PASSENGER_TYPE_LABEL = {
  [EPassengerType.ADT]: 'Người lớn',
  [EPassengerType.CHD]: 'Trẻ em',
  [EPassengerType.INF]: 'Trẻ sơ sinh',
};

export const PAXTYPE_LABEL = {
  [EPaxType.ADT]: 'Người lớn',
  [EPaxType.CHD]: 'Trẻ em',
  [EPaxType.INF]: 'Trẻ sơ sinh',
};

export const PAX_TYPE_SHORT_LABEL = {
  [EPaxType.ADT]: 'adt',
  [EPaxType.CHD]: 'chd',
  [EPaxType.INF]: 'inf',
};

export const GENDER_TYPE_LABEL = {
  [EGender.GENDER_TYPE_MALE]: 'Nam',
  [EGender.GENDER_TYPE_FEMALE]: 'Nữ',
};

export const PRONOUN_TYPE_LABEL = {
  [EPronoun.MALE]: 'Ông',
  [EPronoun.FEMALE]: 'Bà',
};

export const DEFAULT_PASSENGER_INFO: IPassengerForm = {
  firstName: '',
  lastName: '',
  dob: null,
  gender: EGender.GENDER_TYPE_NONE,
  type: EPassengerType.ADT,
  passportNumber: '',
  expirationDate: null,
  nationality: '',
};

export const GENDER_OPTIONS = [
  {
    label: GENDER_TYPE_LABEL[EGender.GENDER_TYPE_MALE],
    value: EGender.GENDER_TYPE_MALE,
  },
  {
    label: GENDER_TYPE_LABEL[EGender.GENDER_TYPE_FEMALE],
    value: EGender.GENDER_TYPE_FEMALE,
  },
];

export const PRONOUN_OPTIONS = [
  {
    label: PRONOUN_TYPE_LABEL[EPronoun.MALE],
    value: EPronoun.MALE,
  },
  {
    label: PRONOUN_TYPE_LABEL[EPronoun.FEMALE],
    value: EPronoun.FEMALE,
  },
];

export const BOOKING_STATUS_LABEL = {
  [EBookingStatus.COMPLETE]: 'Đã xuất vé',
  [EBookingStatus.INPROGRESS]: 'Đang giữ chỗ',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'Xuất vé thất bại',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'Đã xuất vé',
};
export enum EIssueTicketError {
  BOOKING_NOT_FOUND = 'Không tìm thấy đơn hàng',
  BOOKING_IS_EXPIRED = 'Đơn hàng đã hết hạn',
  BOOKING_IS_CANCELLED = 'Đơn hàng đã bị hủy',
  BOOKING_IS_FAILED = 'Đơn hàng bị lỗi',
  BOOKING_IS_COMPLETED = 'Đơn hàng đã được xuất trước đó',
  INSUFFICIENT_BALANCE = 'Không đủ số dư ví',
  SOMETHING_ERROR = 'Lỗi xuất vé, vui lòng chọn lại chuyến bay khác!',
}
