import GroupSearchAirport from './GroupSearchAirport';
import { Button, CrossLineIcon, FormInputDate, TInputSize } from '@common-ui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IFormSearchFlight } from '@web-booker/types/ui/flight';
import { DEFAULT_SEARCH_MAX_DATE } from '@web-booker/hooks/apps/useSearchFlightForm';

type Props = {
  inputSize?: TInputSize;
};

type ItemMultipleTripProps = {
  allowRemove?: boolean;
  inputSize?: TInputSize;
  title: string;
  onRemove: () => void;
  name: string;
  minDate: Date;
};

const ItemMultipleTrip = ({
  inputSize,
  title,
  onRemove,
  allowRemove,
  name,
  minDate,
}: ItemMultipleTripProps) => {
  return (
    <div className='space-y-2 '>
      <span className='text-sm font-semibold'>{title}</span>
      <div className='flex flex-col space-y-2.5'>
        <GroupSearchAirport
          startPointName={`${name}.startPoint`}
          endPointName={`${name}.endPoint`}
          inputSize={inputSize}
        />
        <div className='grid grid-cols-2'>
          <div className='col-span-1'>
            <FormInputDate
              placeholder='Ngày đi'
              name={`${name}.departDate`}
              inputProps={{
                showMonthDropdown: false,
                minDate: minDate,
                maxDate: DEFAULT_SEARCH_MAX_DATE,
                inputSize: inputSize,
              }}
              rules={{
                required: 'Chọn ngày đi',
                validate: {
                  isValidDate: (v) => {
                    return (
                      (v && new Date(v) > new Date(minDate.getTime() - 1)) ||
                      'Vui lòng cập nhật lại thời gian đi'
                    );
                  },
                },
              }}
            />
          </div>
          {allowRemove && (
            <div className='flex justify-end col-span-1'>
              <div
                className=' flex space-x-1 cursor-pointer items-center '
                onClick={() => onRemove()}>
                <CrossLineIcon />
                <span className='text-sm'>Xóa chặng</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const MultipleTrip = ({ inputSize }: Props) => {
  const methods = useFormContext<IFormSearchFlight>();
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'flightList',
  });

  const listDepartDate = methods.watch(
    fields.map((item, index) => `flightList[${index}].departDate`) as any
  ) as Date[];

  function getMinDate(index: number): Date {
    let checkIndex = index - 1;
    while (checkIndex >= 0) {
      const departDate = listDepartDate[checkIndex];
      if (departDate) {
        return new Date(departDate);
      }
      checkIndex--;
    }
    return new Date(new Date().toLocaleDateString());
  }

  const handleAppendItem = () => {
    append({
      departDate: null,
      endPoint: null,
      startPoint: null,
    });
  };

  const handleRemoveItem = (index: number) => {
    if (fields.length > 2) {
      remove(index);
    }
  };

  return (
    <div className='flex space-y-4 flex-col pb-2'>
      {fields.map((item, index) => {
        return (
          <ItemMultipleTrip
            key={item.id}
            inputSize={inputSize}
            name={`flightList[${index}]`}
            onRemove={() => handleRemoveItem(index)}
            title={`Chặng bay ${index + 1}`}
            allowRemove={fields.length > 2}
            minDate={getMinDate(index)}
          />
        );
      })}
      {fields.length < 5 && (
        <Button type='button' onClick={() => handleAppendItem()}>
          Thêm chặng bay
        </Button>
      )}
    </div>
  );
};

export default MultipleTrip;
