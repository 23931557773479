import { LoadingRing } from '@common-ui';
import 'antd/dist/reset.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routeStructure } from './routers/routes';
const routes = createBrowserRouter(routeStructure);

export function App() {
  return (
    <RouterProvider
      fallbackElement={
        <div className='flex justify-center items-center h-[300px] w-full'>
          <LoadingRing />
        </div>
      }
      router={routes}
    />
  );
}

export default App;
