import { Combobox, Transition } from '@headlessui/react';
import CheckIcon from '@heroicons/react/20/solid/CheckIcon';
import cn from 'classnames';
import { ForwardedRef, Fragment, forwardRef, useEffect, useState } from 'react';

// import {
//   FlightLandingIcon,
//   FlightTakeOffIcon,
//   PlaneFillIcon,
// } from '@/components/Icons';

import {
  MapPin2FillIcon,
  PassPropsType,
  PlaneFillIcon,
  TBaseInputProps,
} from '@common-ui';

import { AirportName } from '@web-booker/components/Flights/AirportName';
import { useThrottleValue } from '@web-booker/hooks/internals';

import { InputSize } from '@web-booker/utils';
import { IAirportInfo, IGroupAirport } from '@tixlabs/grpc-client/web-partner';

export type InputSearchAirportProps = {
  groupFlightData: IGroupAirport[];
  mode: 'take-off' | 'landing';
  className?: string;
  inputSearchClassName?: string;
  disabled?: boolean;
  handleSearch: (keyword: string) => void;
} & PassPropsType<IAirportInfo> &
  TBaseInputProps;

function compareFlight(a: IAirportInfo, b: IAirportInfo) {
  if (a) {
    return a.id === b.id;
  }
  return false;
}

function InputSearchAirportInner(
  {
    name,
    disabled,
    className,
    inputSearchClassName,
    isError,
    placeholder,
    value,
    onChange,
    inputSize = InputSize.MD,
    mode,
    handleSearch,
    groupFlightData,
  }: InputSearchAirportProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [query, setQuery] = useState('');
  const [isFocusSearch, setIsFocusSearch] = useState(false);

  const throttleQuery = useThrottleValue<string>(query, 500);
  const trimQuery = query.trim();

  function getDisplayValue() {
    if (!value) {
      return '';
    }

    return [value.city, value.country].join(', ');
  }

  useEffect(() => {
    // if (isFocusSearch) {
    //   console.log('throttleQuery', throttleQuery);
    //   handleSearch(throttleQuery);
    // }
    if (throttleQuery && isFocusSearch) {
      handleSearch(throttleQuery);
    }
  }, [throttleQuery]);

  return (
    <div className={cn('text-black', className)}>
      <Combobox
        by={compareFlight}
        value={value}
        disabled={!!disabled}
        onChange={onChange}>
        <div className='relative'>
          <div
            className={cn(
              'base-select flex items-center space-x-2 ',
              `base-select-flight-${inputSize}`,
              inputSearchClassName,

              {
                error: isError,
                'bg-theme-black/5 ': disabled,
              }
            )}>
            <Combobox.Button>
              {mode === 'take-off' && (
                <MapPin2FillIcon
                  className={cn('h-5 w-5 text-theme-black/50')}
                />
              )}
              {mode === 'landing' && (
                <MapPin2FillIcon
                  className={cn('h-5 w-5 text-theme-black/50')}
                />
              )}
            </Combobox.Button>

            <Combobox.Input
              ref={ref}
              onFocus={() => {
                setIsFocusSearch(true);
              }}
              onBlur={() => {
                setIsFocusSearch(false);
              }}
              className={cn('w-full bg-transparent text-left')}
              displayValue={getDisplayValue}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
            />
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Combobox.Options
              className={cn([
                'absolute z-popover mt-1  w-full max rounded-xl text-sm border z-20 ',
                'bg-theme-white shadow-popover-search-flight',
                {
                  'py-2': trimQuery !== '' || groupFlightData.length,
                },
              ])}>
              <div className='py-3 max-h-[366px] overflow-auto'>
                {groupFlightData.length === 0 && trimQuery !== '' ? (
                  <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
                    Nothing found.
                  </div>
                ) : (
                  groupFlightData.map((item, index) => (
                    <div key={`group_${index}`}>
                      <div className='px-4 py-2'>{item.label}</div>
                      {item.airportList.map((airport) => (
                        <Combobox.Option
                          key={airport.id}
                          value={airport}
                          className={({ active, selected }) =>
                            cn(
                              'relative cursor-pointer select-none space-x-2 px-4 text-theme-black w-full',
                              active || selected ? 'bg-primary/5 ' : ''
                            )
                          }>
                          <div className='flex items-center space-x-2 border-b border-theme-black/20 py-2 pr-2'>
                            <PlaneFillIcon
                              className='h-5 w-5 flex-shrink-0 text-theme-black'
                              aria-hidden='true'
                            />
                            <AirportName
                              name={airport.name}
                              codeAirport={airport.code}
                              className='truncate text-sm font-normal ui-selected:font-medium'
                            />
                            <CheckIcon
                              className='invisible absolute right-2 top-1/2 h-6 w-6 flex-shrink-0 -translate-y-1/2 text-primary ui-selected:visible'
                              aria-hidden='true'
                            />
                          </div>
                        </Combobox.Option>
                      ))}
                    </div>
                  ))
                )}
              </div>
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
export const InputSearchAirport = forwardRef(InputSearchAirportInner);
InputSearchAirport.displayName = 'InputSearchAirport';

export default InputSearchAirport;
