import {
  AirPlaneIcon,
  BookingIcon,
  Home5LineIcon,
  LogoSkyBookingIcon,
  MenuLineIcon,
  OrdersIcon,
} from '@icon';
import { MenuHeader } from '@web-booker/components/Header';
import { useUserStore } from '@web-booker/hooks/stores';
import { Button, Layout, Menu } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

const items = [
  {
    key: 'dashboard',
    label: 'Trang chủ',
    icon: <Home5LineIcon className='shrink-0 w-4 h-4 ' />,
  },
  {
    key: 'booking-flight/search',
    label: 'Đặt chuyến bay',
    icon: <AirPlaneIcon className='shrink-0 w-4 h-4' />,
  },
  {
    key: 'order-management',
    label: 'Quản lý đặt chỗ',
    icon: <OrdersIcon className='shrink-0 w-4 h-4' />,
  },
  {
    key: 'transaction-management',
    label: 'Quản lý giao dịch',
    icon: <BookingIcon className='shrink-0 w-4 h-4' />,
  },
  // {
  //   key: 'ticket-management',
  //   label: 'Quản lý xuất vé',
  //   icon: <TicketIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'management',
  //   label: 'QUẢN LÝ',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'agency',
  //       label: 'Thông tin đại lý',
  //       icon: <InformationLineIcon className='shrink-0 w-4 h-4' />,
  //     },
  //     {
  //       key: 'user',
  //       label: 'Quản lý người dùng',
  //       icon: <GroupLineIcon className='shrink-0 w-4 h-4' />,
  //     },
  //   ],
  // },
  // {
  //   key: 'finance',
  //   label: 'TÀI CHÍNH',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'service-fee',
  //       label: 'Cài đặt phí dịch vụ',
  //       icon: <ListSettingsFillIcon className='shrink-0 w-4 h-4' />,
  //     },
  //     {
  //       key: 'payment-history',
  //       label: 'Lịch sử thanh toán',
  //       icon: <HistoryFillIcon className='shrink-0 w-4 h-4' />,
  //     },
  //   ],
  // },
  // {
  //   key: 'statistical',
  //   label: 'THỐNG KÊ',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'search-statistical',
  //       label: 'Lượng tìm kiếm',
  //       icon: <SearchLineIcon className='shrink-0 w-4 h-4' />,
  //     },
  //     {
  //       key: 'order-statistical',
  //       label: 'Lượng đặt chỗ',
  //       icon: <OrdersIcon className='shrink-0 w-4 h-4' />,
  //     },
  //     {
  //       key: 'issue-statistical',
  //       label: 'Lượng xuất vé',
  //       icon: <TicketIcon className='shrink-0 w-4 h-4' />,
  //     },
  //   ],
  // },
  // {
  //   key: 'notification',
  //   label: 'THÔNG BÁO',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'email-structure',
  //       label: 'Cấu trúc email',
  //       icon: <MailLineIcon className='shrink-0 w-4 h-4' />,
  //     },
  //     {
  //       key: 'notifcation-structure',
  //       label: 'Cấu trúc thông báo',
  //       icon: <Notification3FillIcon className='shrink-0 w-4 h-4' />,
  //     },
  //     {
  //       key: 'ticket-structure',
  //       label: 'Cấu trúc mặt vé điện tử',
  //       icon: <TicketIcon className='shrink-0 w-4 h-4' />,
  //     },
  //   ],
  // },
];

export const DashboardLayout = () => {
  const topContentRef = useRef<HTMLDivElement>(null);
  const { userData } = useUserStore();
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // e.target.scrollIntoView({
  //   behavior: 'smooth',
  //   block: 'nearest',
  //   inline: 'start',
  // });

  useEffect(() => {
    topContentRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }, [pathname]);

  return (
    <Layout
      style={{
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}>
      <Header>
        <div className='flex justify-between h-full items-center text-base'>
          <div className='flex space-x-5 items-center'>
            <div>
              <LogoSkyBookingIcon className='shrink-0' />
            </div>

            <Button
              type='text'
              icon={<MenuLineIcon />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                color: 'white',
                fontSize: '16px',
              }}
            />
          </div>
          <MenuHeader name={userData?.name || ''} />
        </div>
      </Header>
      <Layout>
        <Sider
          breakpoint='lg'
          onBreakpoint={(isBroken) => {
            if (isBroken) {
              setCollapsed(true);
            }
          }}
          collapsed={collapsed}
          style={{ padding: '20px 10px' }}
          collapsedWidth={80}
          width={270}>
          <Menu
            mode='inline'
            defaultSelectedKeys={['home']}
            defaultOpenKeys={['home']}
            items={items}
            selectedKeys={pathname.split('/')}
            onClick={(info) => {
              navigate(info.keyPath.join('/'));
            }}
          />
        </Sider>

        <Content
          id='right-content'
          className='p-2.5 overflow-x-hidden overflow-y-auto'
          rootClassName='bg-[#F0F2F5]'>
          <div ref={topContentRef}></div>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
