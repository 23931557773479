// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import rootReducer from './reducers/root';

// import { configureAppStore } from '@core/redux';

// export const store = configureAppStore({
//   reducer: rootReducer,
// });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

// export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from 'redux-persist';
import rootReducer from './reducers/root';
function configureAppStore(preloadedState: Record<string, any> | undefined) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            'booking/updateContactPassenger',
            'booking/saveMapSeatData',
            'searchFlight/saveSearchDataHistoryFullData',
          ],
          ignoredPaths: ['searchFlight.searchDataHistoryFullData.itemMaps'],
        },
      }),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
}

export const store = configureAppStore({});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
