import { Form, LoadingRing } from '@common-ui';
import { useClientPagination } from '@core/hooks';
import { Calendar2LineIcon, PlaneIcon } from '@icon';
import { IFlightInfo, IFlightReq } from '@tixlabs/grpc-client/web-partner';

import NotFoundFlight from '@web-booker/components/Flights/NotFoundFlight';
import { useCities } from '@web-booker/hooks/apps';
import { useTime } from '@web-booker/hooks/internals';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { updateSortClient } from '@web-booker/stores/reducers/searchFlight';
import { EItineraryType } from '@web-booker/types';
import { ESortType, IFormSortFlight } from '@web-booker/types/ui/flight';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SortFlightCtn } from '../SortFlight';
import { MinFareList } from './MinFareList';
import SearchFlightItem from './SearchFlightItem';
import useFlightList from './useFlightList';
import useMinFare from './useMinFare';

type Props = {
  //
};

function CurrentSelectInfo({ data }: { data: IFlightReq }) {
  const { getCityNameByCode } = useCities();
  const { formatDate } = useTime();

  return (
    <div className='bg-primary-6 text-neutral-1 text-md space-y-1.5 p-2.5 rounded-t'>
      <div className='flex items-center gap-2.5'>
        <PlaneIcon className='w-5 h-5' />
        <div>
          Chuyến bay từ{' '}
          <span className='font-semibold'>
            {getCityNameByCode(data.startPoint)}
          </span>{' '}
          đến{' '}
          <span className='font-semibold'>
            {getCityNameByCode(data.endPoint)}
          </span>{' '}
        </div>
      </div>
      <div className='flex items-center gap-2.5'>
        <Calendar2LineIcon className='w-5 h-5' />
        <div>Ngày {formatDate(data.departDate)}</div>
      </div>
    </div>
  );
}

const DEFAULT_SORT_OPTION_LIST = [
  {
    label: 'Hãng hàng không',
    type: ESortType.AIR_LINE,
    asc: true,
    isSelected: false,
  },
  {
    label: 'Giờ khởi hành',
    type: ESortType.DEPART_DATE,
    asc: true,
    isSelected: false,
  },
  {
    label: 'Giờ hạ cánh',
    type: ESortType.ARRIVAL_DATE,
    asc: true,
    isSelected: false,
  },
  {
    label: 'Giá vé',
    type: ESortType.TICKET_PRICE,
    asc: true,
    isSelected: true,
  },
];

export function FlightList({}: Props) {
  const {
    minFareList,
    activeDate,
    handleChangeMinFare,
    isLoading: isLoadingMinFare,
  } = useMinFare();

  const dispatch = useAppDispatch();
  const methodSortForm = useForm<IFormSortFlight>({
    defaultValues: {
      sortsList: DEFAULT_SORT_OPTION_LIST,
    },
  });

  const watchSort = methodSortForm.watch('sortsList');

  const { currentSearchData, currentSelectIndex, isLoading, isIdle } =
    useFlightList();

  const {
    filter: { flightsList, passengerQuantity },
    itineraryType,
  } = useAppSelector((state) => state.searchFlight);

  const {
    data: dataList,
    handleMore,
    isMore,
  } = useClientPagination<IFlightInfo>(currentSearchData?.itemsList ?? [], {
    limit: 8,
  });

  function submitSortData() {
    const sortData = watchSort.find((option) => option.isSelected);

    if (sortData) {
      dispatch(
        updateSortClient({
          asc: sortData.asc,
          type: sortData.type,
        })
      );
    }
  }

  useEffect(() => {
    submitSortData();
  }, [watchSort]);

  return (
    <div>
      {currentSelectIndex >= 0 && flightsList.length && (
        <>
          <Form
            className='mb-5'
            methods={methodSortForm}
            onSubmit={() => {
              //
            }}>
            <SortFlightCtn />
          </Form>

          <div className='mb-1.5'>
            <CurrentSelectInfo data={flightsList[currentSelectIndex]} />
          </div>
          {itineraryType !== EItineraryType.MULTIPLE_TRIP && (
            <div className='mb-2.5'>
              <MinFareList
                minFareList={minFareList}
                activeDate={activeDate}
                handleChangeMinFare={handleChangeMinFare}
                isLoading={isLoadingMinFare || isLoading}
              />
            </div>
          )}
        </>
      )}
      {/* {`isLoading:${isLoading}`}
      {`isLoadingMinFare:${isLoadingMinFare}`} */}
      <div className='space-y-1.5 '>
        {isLoading || isLoadingMinFare ? (
          [0, 1, 2, 3, 4, 5].map((k) => (
            <div key={k} className='overflow-hidden duration-300'>
              <div className='relative h-[122px] flex animate-pulse justify-between overflow-hidden bg-neutral-5 duration-300 '></div>
            </div>
          ))
        ) : currentSearchData?.itemsList.length > 0 ? (
          <InfiniteScroll
            style={{
              overflow: 'initial',
            }}
            className='space-y-1.5 pb-10 relative'
            scrollableTarget='right-content'
            dataLength={dataList.length || 0}
            next={() => {
              handleMore();
            }}
            hasMore={isMore}
            loader={
              <div className='flex justify-center items-center w-full py-4'>
                <LoadingRing size='md' />
              </div>
            }

            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
          >
            {dataList.map((flightItem, index) => (
              <SearchFlightItem
                passengerQuantity={
                  passengerQuantity || { adt: 1, chd: 0, inf: 0 }
                }
                key={flightItem.itineraryKey}
                data={flightItem}
              />
            ))}
          </InfiniteScroll>
        ) : isIdle ? (
          <div className='text-center'>Tìm chuyến bay</div>
        ) : (
          <NotFoundFlight />
        )}
      </div>
    </div>
  );
}

export default FlightList;
