export const IP_COUNTRY_KEY = 'cf-ipcountry';
export const IP_COUNTRY_DEFAULT = 'VN';
export const VN_COUNTRY_CODE = 'VN';
export const ONE_YEAR_TIMESTAMP = 365 * 24 * 60 * 60 * 1000;
export const ONE_DAY_TIMESTAMP = 24 * 60 * 60 * 1000;

export const SUPPORT_FILES = [
  'image/jpeg',
  'image/png',
  'image/avif',
  'image/tiff',
  'image/heic',
  'image/heif',
  'image/webp',
  'application/pdf',
];

export const SUPPORT_IMAGES_TYPE = [
  'image/png',
  'image/bmp',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
];
