import {
  CalculationFlightFeeResData,
  CalculationFlightFeeResItem,
  FlightItineraryType,
  PaxType,
  BookingStatus,
} from '@api/airplane/base_pb';
import { ReportAirlineBookingItem, ReportBookingItem } from './report_pb';
import { FlightServiceFee } from '@api/airplane/base_pb';

import {
  BookingItinerary,
  InvoicingInformation,
  BookingPassenger,
  Segment,
} from './flight_pb';
import { Airline, HubBaggage, HubItinerary } from './hub_pb';

export interface IFlightServiceFee extends FlightServiceFee.AsObject {}
export type EFlightItineraryType = FlightItineraryType;
export const EFlightItineraryType = { ...FlightItineraryType } as const;
export interface ICalculationFlightFeeRes
  extends CalculationFlightFeeResData.AsObject {}

export interface IItemCalculationFlightFee
  extends CalculationFlightFeeResItem.AsObject {}

export interface IAirlineHub extends Airline.AsObject {}

export interface IBookingItinerary extends BookingItinerary.AsObject {}
export interface IBookingPassenger extends BookingPassenger.AsObject {}

export interface IInvoicingInformation extends InvoicingInformation.AsObject {}

export interface IHubItinerary extends HubItinerary.AsObject {}
export interface IHubBaggage extends HubBaggage.AsObject {}
export interface ISegment extends Segment.AsObject {}
export type EBookingStatus = BookingStatus;
export const EBookingStatus = { ...BookingStatus } as const;
export type EPaxType = PaxType;
export const EPaxType = { ...PaxType } as const;
export interface IReportBookingItem extends ReportBookingItem.AsObject {}
export interface IReportAirlineBookingItem
  extends ReportAirlineBookingItem.AsObject {}
