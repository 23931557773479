import { Card, HistoryFillIcon, PeopleFillIcon } from '@common-ui';
import { ISearchFlightFilter } from '@tixlabs/grpc-client/web-partner';
import useHideContent from '@web-booker/components/Common/HideContent/useHideContent';
import { useTime } from '@web-booker/hooks/internals';
import { useAirport } from '@web-booker/hooks/stores';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  syncSearchHistory,
  updateSelectHistory,
} from '@web-booker/stores/reducers/searchFlightHistory';
import { EItineraryType } from '@web-booker/types';
import { getItineraryType } from '@web-booker/utils';
import { itineraryTypeLabel } from '@web-booker/utils/constants/flight';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

type Props = {
  //
};
type TTypeJourneyProps = {
  numberOfFlight: number;
  type: EItineraryType | null;
};

// const DeleteOption = () => (
//   <PopoverArrow
//     label={<More2FillIcon />}
//     isShowArrow={false}
//     panelClassName='left-auto right-0'>
//     {({ close }) => {
//       return (
//         <div className='p-2 bg-white'>
//           <div
//             className='flex cursor-pointer space-x-2 items-center p-1 hover:bg-slate-50 rounded min-w-[100px] text-sm '
//             onClick={() => {
//               close();
//             }}>
//             <DeleteBin6Line />
//             <div>Xóa lịch sử</div>
//           </div>
//         </div>
//       );
//     }}
//   </PopoverArrow>
// );

const TypeJourney = ({ type, numberOfFlight }: TTypeJourneyProps) => {
  if (!type) return null;

  return (
    <div
      className={cn('rounded-md px-2 py-1 text-xs font-thin text-white', {
        'bg-flight-type-one': type === EItineraryType.ONE_WAY,
        'bg-flight-type-round': type === EItineraryType.ROUND_TRIP,
        'bg-flight-type-multiple': type === EItineraryType.MULTIPLE_TRIP,
      })}>
      {type === EItineraryType.MULTIPLE_TRIP
        ? `${numberOfFlight} chặn`
        : itineraryTypeLabel[type]}
    </div>
  );
};
const SearchHistoryItem = ({
  data,
  onClick,
  className,
}: {
  data: ISearchFlightFilter;
  className?: string;
  onClick?: () => void;
}) => {
  const { formatDate } = useTime();
  const firstFlight = data.flightsList?.[0];
  const itineraryType = getItineraryType(data.flightsList);

  const passengerQuantity = data.passengerQuantity
    ? data.passengerQuantity.adt +
      data.passengerQuantity.chd +
      data.passengerQuantity.inf
    : 0;

  return (
    <div
      onClick={onClick}
      className={cn(
        className,
        'grid grid-cols-2 border-b border-[#E6E6E6] p-1 gap-x-4 cursor-pointer h-10 items-center hover:bg-neutral-4'
      )}>
      <div className='col-span-1 flex min-w-[170px] items-center justify-between text-sm font-semibold'>
        <span className='flex-1'>
          {firstFlight?.startPoint} {firstFlight?.endPoint}
        </span>
        <div className='flex flex-1 justify-start'>
          <TypeJourney
            type={itineraryType}
            numberOfFlight={data.flightsList.length}
          />
        </div>
      </div>
      <div className='col-span-1 flex items-center justify-between px-1'>
        <div className='text-xs'>
          <div className='leading-none'>
            {formatDate(data.flightsList?.[0].departDate)}
          </div>
          {itineraryType === EItineraryType.ROUND_TRIP && (
            <div className='leading-none'>
              {formatDate(data.flightsList?.[1].departDate)}
            </div>
          )}
        </div>
        <div className='min-w-[20px] text-center'>{passengerQuantity}</div>
      </div>
    </div>
  );
};

export const SearchHistoryCtn = (props: Props) => {
  const dispatch = useAppDispatch();
  const historyListElement = useRef<HTMLDivElement>(null);
  const { setFalse } = useHideContent();

  const { fetchAirports } = useAirport();
  const { searchHistoryList } = useAppSelector(
    (state) => state.searchFlightHistory
  );

  const { isLoading } = useAppSelector((state) => state.searchFlight);

  function handleSearchHistory(data: ISearchFlightFilter) {
    dispatch(updateSelectHistory(data));
    setFalse();
  }

  useEffect(() => {
    dispatch(syncSearchHistory());
  }, []);

  useEffect(() => {
    if (searchHistoryList.length) {
      const objectAirportCode = {};

      searchHistoryList.forEach((item) => {
        item.flightsList.forEach((fItem) => {
          objectAirportCode[fItem.startPoint] = true;
          objectAirportCode[fItem.endPoint] = true;
        });
      });

      const listCode = Object.keys(objectAirportCode);
      if (listCode.length) {
        fetchAirports(listCode);
      }
      if (historyListElement.current) {
        historyListElement.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [searchHistoryList]);

  return (
    <Card
      title='Tìm kiếm gần đây'
      isCollapse
      icon={<HistoryFillIcon />}
      // rightIcon={<DeleteOption />}
      defaultShow
      classNameChildren='p-2.5 flex flex-col pb-4'>
      <div>
        <div className='grid grid-cols-2 items-center font-semibold gap-x-4 mb-2'>
          <div className='col-span-1'>Hành trình</div>
          <div className='col-span-1 flex items-center justify-between'>
            <div>Ngày đi</div>
            <PeopleFillIcon className='mr-2' />
          </div>
        </div>
        <div
          ref={historyListElement}
          className='max-h-56 overflow-y-auto scroll-w-sm'>
          {searchHistoryList.map((item, index) => {
            return (
              <SearchHistoryItem
                onClick={() => {
                  !isLoading && handleSearchHistory(item);
                }}
                data={item}
                key={index}
                className={index % 2 ? '' : 'bg-neutral-3 '}
              />
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default SearchHistoryCtn;
