import { convertArrayToObject } from '@core/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAirlines } from '@tixlabs/axios-client';
import { IAirline, ICity, ICountry } from '@tixlabs/types';
import { ECurrency } from '@web-admin/types/common';

export interface ICurrency {
  text: string;
  value: ECurrency;
  symbol: string;
}

interface ICommonState {
  countries?: ICountry[];
  errors: {
    [key: string]: string;
  };
  countriesObjectByCode?: Record<string, ICountry>;
  cities?: ICity[];
  citiesObjectByCode?: Record<string, ICity>;
  airlines?: IAirline[];
  airlinesObjectByCode?: Record<string, IAirline>;
  currency: ICurrency;
  showMenuMobile: boolean;
  loadingAirlines?: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState = {
  currency: { text: 'VND', value: ECurrency.VND, symbol: 'VND' },
  loadingAirlines: 'idle',
  showMenuMobile: false,
} as ICommonState;

export const fetchAirlines = createAsyncThunk(
  'common/fetchAirlines',
  async (_, { dispatch }) => {
    const { data } = await getAirlines();
    if (data?.airlines) {
      dispatch(updateAirlines(data.airlines));
    }
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateCountries(state, action: PayloadAction<ICountry[]>) {
      state.countries = action.payload;
      state.countriesObjectByCode = convertArrayToObject<ICountry>(
        action.payload,
        'countryCode'
      );
    },
    updateCities(state, action: PayloadAction<ICity[]>) {
      state.cities = action.payload;
      state.citiesObjectByCode = convertArrayToObject<ICity>(
        action.payload,
        'code'
      );
    },
    updateErrors(state, action) {
      state.errors = action.payload;
    },
    updateAirlines(state, action: PayloadAction<IAirline[]>) {
      state.airlines = action.payload;
      state.airlinesObjectByCode = convertArrayToObject<IAirline>(
        action.payload,
        'code'
      );
    },

    toggleMenuMobile(state, action: PayloadAction<boolean>) {
      state.showMenuMobile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirlines.pending, (state, action) => {
        state.loadingAirlines = action.meta.requestStatus;
      })
      .addCase(fetchAirlines.rejected, (state, action) => {
        state.loadingAirlines = action.meta.requestStatus;
      })
      .addCase(fetchAirlines.fulfilled, (state, action) => {
        state.loadingAirlines = action.meta.requestStatus;
      });
  },
});

export const {
  updateCountries,
  // updateActiveCurrency,
  updateErrors,
  updateAirlines,
  updateCities,
  toggleMenuMobile,
} = commonSlice.actions;
export default commonSlice.reducer;
