import {
  CheckFillIcon,
  ErrorIcon,
  StatusInProcessIcon,
  StatusNewIcon,
} from '@icon';
import cn from 'classnames';

enum Status {
  NEW,
  IN_PROCESS,
  DONE,
  CANCEL,
}

type TProps = {
  status: Status;
};

const content = {
  [Status.NEW]: (
    <>
      <StatusNewIcon className='text-inherit w-4 h-4 shrink-0' />
      <span>Mới đặt</span>
    </>
  ),
  [Status.IN_PROCESS]: (
    <>
      <StatusInProcessIcon className='text-inherit w-4 h-4 shrink-0' />
      <span>Đang xử lý</span>
    </>
  ),
  [Status.DONE]: (
    <>
      <CheckFillIcon className='text-inherit w-4 h-4 shrink-0' />
      <span>Đã thanh toán</span>
    </>
  ),
  [Status.CANCEL]: (
    <>
      <ErrorIcon className='text-inherit w-4 h-4 shrink-0' />
      <span>Đã hủy</span>
    </>
  ),
};

export const StatusOrder = ({ status }: TProps) => {
  return (
    <div
      className={cn('flex space-x-2 items-center whitespace-nowrap', {
        'text-secondary': status === Status.NEW,
        'text-[#E4B200]': status === Status.IN_PROCESS,
        'text-[#0B9D58]': status === Status.DONE,
        'text-[#999999]': status === Status.CANCEL,
      })}>
      {content[status]}
    </div>
  );
};

export default StatusOrder;
